import React from "react";
import { cloneElement } from "react";
import { Box, Typography, List, ListItem, Grid } from "@mui/material";
import logo from "../../assets/images/logo.png";
import Footer from "./Footer";

const addNumbersToListItems = (list) => {
  const getAlphabet = (index) => String.fromCharCode(97 + index); // 'a' + index for alphabetic order

  const addAlphabetToNestedList = (children) => {
    return React.Children.map(children, (child, index) => {
      if (child.type === ListItem) {
        return cloneElement(child, {
          children: (
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                variant="body1"
                component="span"
                style={{
                  fontWeight: "bold",
                  marginRight: "8px",
                }}
                className="link-color"
              >
                {getAlphabet(index)}.
              </Typography>
              <Box>{child.props.children}</Box>
            </Box>
          ),
        });
      }
      return child;
    });
  };

  return cloneElement(list, {
    children: React.Children.map(list.props.children, (child, index) => {
      if (child.type === ListItem) {
        const nestedList = React.Children.toArray(child.props.children).find(
          (nestedChild) => nestedChild.type === List
        );

        if (nestedList) {
          const updatedNestedList = addAlphabetToNestedList(
            nestedList.props.children
          );

          return cloneElement(child, {
            children: (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Typography
                    variant="body1"
                    component="span"
                    style={{
                      fontWeight: "bold",
                      marginRight: "8px",
                    }}
                    className="link-color"
                  >
                    {index + 1}.
                  </Typography>
                  <Box>
                    {React.Children.toArray(child.props.children).filter(
                      (nestedChild) => nestedChild.type !== List
                    )}
                  </Box>
                </Box>
                <List>{updatedNestedList}</List>
              </Box>
            ),
          });
        } else {
          return cloneElement(child, {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{
                    fontWeight: "bold",
                    marginRight: "8px",
                  }}
                  className="link-color"
                >
                  {index + 1}.
                </Typography>
                <Box>{child.props.children}</Box>
              </Box>
            ),
          });
        }
      }
      return child;
    }),
  });
};

const PrivacyPolicy = () => {
  const list = (
    <List className="policy-list-content">
      <ListItem>
        <Typography className="link-color-head">
          Information We Collect
        </Typography>
        <List>
          <ListItem>
            <Typography className="link-alpha-head">
              Personal Information:
            </Typography>
            <Typography className="policy-item">
              When you register on OpenEssayMe, we collect personal information
              such as your email address, username, and password. This
              information is necessary to create your account and provide you
              with access to our Services.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">Usage Data:</Typography>
            <Typography className="policy-item">
              {" "}
              We collect information about how you use our Services, including
              the types of essays you write, your responses to prompts, and your
              interaction with the AI tutor. This helps us tailor our Services
              to better meet your needs.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">
              Payment Information:
            </Typography>{" "}
            <Typography className="policy-item">
              If you subscribe to our paid plans, we collect payment
              information, which may include credit card numbers or other
              payment details. This information is used solely to complete your
              transactions.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">
              Feedback and Communication:{" "}
            </Typography>{" "}
            <Typography className="policy-item">
              When you provide feedback or contact us for support, we collect
              your communications and any information you provide.
            </Typography>
          </ListItem>
        </List>
      </ListItem>
      <ListItem>
        <Typography className="link-color-head">
          How We Use Your Information
        </Typography>
        <List>
          <ListItem>
            <Typography className="link-alpha-head">
              To Provide Services:
            </Typography>
            <Typography className="policy-item">
              Your information helps us to administer your account, provide the
              Services you request, and enhance your overall experience on our
              platform.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">
              To Improve and Develop:
            </Typography>
            <Typography className="policy-item">
              {" "}
              We use information to analyze and understand how our Services are
              used and to make improvements.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">
              For Communication:
            </Typography>{" "}
            <Typography className="policy-item">
              We may use your information to contact you with updates, security
              alerts, and administrative messages.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className="link-alpha-head">For Marketing:</Typography>{" "}
            <Typography className="policy-item">
              With your consent, we may use your information to send you
              promotional messages and information about new services we think
              you might find valuable.
            </Typography>
          </ListItem>
        </List>
      </ListItem>
      <ListItem>
        <Typography className="link-alpha-head">
          Sharing Your Information
        </Typography>{" "}
        <Typography className="policy-item">
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does not include website
          hosting partners and other parties who assist us in operating our
          website, conducting our business, or serving our users, so long as
          those parties agree to keep this information confidential.
        </Typography>
      </ListItem>
      <ListItem>
        <Typography className="link-alpha-head">Data Security</Typography>{" "}
        <Typography className="policy-item">
          We implement a variety of security measures to maintain the safety of
          your personal information when you enter, submit, or access your
          information.
        </Typography>
      </ListItem>
      <ListItem>
        <Typography className="link-alpha-head">Your Rights</Typography>{" "}
        <Typography className="policy-item">
          You have the right to access, update, or delete your personal
          information at any time. If you wish to exercise these rights, please
          contact us at [insert contact information].
        </Typography>
      </ListItem>
      <ListItem>
        <Typography className="link-alpha-head">
          Changes to Our Privacy Policy
        </Typography>{" "}
        <Typography className="policy-item">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </Typography>
      </ListItem>
      <ListItem>
        <Typography className="link-alpha-head">Contact Us</Typography>{" "}
        <Typography className="policy-item">
          If you have any questions about these Terms, please contact us
          at&nbsp;
          <a href="mailto:support@openessayme.com" className="link-color">
            support@openessayme.com
          </a>
        </Typography>
      </ListItem>
    </List>
  );

  const numberedList = addNumbersToListItems(list);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1c2536", // Set your desired background color here
          minHeight: "100vh", // Ensure it covers the full height of the viewport
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "1000px", margin: "auto" }}>
          <Box className="policy-content-header-title">
            <Box class="setting-icon">
              <img src={logo} alt="app-logo" loading="lazy" />
            </Box>
            <Typography variant="h2" component="h2" className="link-color">
              PRIVACY POLICY
            </Typography>
          </Box>
          <Box className="policy-content-layout">
            <Box variant="div" component="div" className="createuser-wrap">
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            <Typography className="policy-item">
                              Effective Date: June 01, 2024
                            </Typography>
                            <Typography className="policy-item">
                              Welcome to OpenEssayMe! We are committed to
                              protecting your privacy and ensuring that your
                              personal information is collected and used
                              properly, transparently, and securely. Our Privacy
                              Policy explains how we collect, use, share, and
                              protect information in relation to our
                              website,&nbsp;
                              <a
                                href={`${process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/`}
                                className="link-redirect"
                              >
                                OpenEssayMe
                              </a>
                              &nbsp;
                              {/* <a
                                href={`${process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/`}
                                className="link-color"
                              >
                                (https://openessayme.com)
                              </a> */}
                              and any associated services, sales, marketing, or
                              events (collectively referred to as "Services").
                            </Typography>
                            <div className="policy-item">{numberedList}</div>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
