import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const cancelSubscriptionSlice = createSlice({
  name: "cancelSubscription",
  initialState,
  reducers: {
    cancelSubscriptionStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    cancelSubscriptionSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    cancelSubscriptionFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  cancelSubscriptionStart,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
} = cancelSubscriptionSlice.actions;

export default cancelSubscriptionSlice.reducer;

export const cancelSubscription = () => async (dispatch) => {
  const token = getToken();
  try {
    // dispatch(setLoading(true));
    dispatch(cancelSubscriptionStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscription/cancel-subscription/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    dispatch(cancelSubscriptionSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(cancelSubscriptionFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
