import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  ListItemIcon,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchConversations } from "../store/slices/interaction/conversationSlice";
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteConversationModal from "../pages/chatbot/deleteModal";
import getuserData from "../utils/userData";
import { Collapse } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import CreateChatModal from "./createChatModal";

const ChatSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const conversations = useSelector(
    (state) => state.conversations.conversations
  );
  const [showDashboardSubMenu, setShowDashboardSubMenu] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const handleNavigate = (path) => {
    navigate(path);
  };

  const isMenuActive = (itemPath) => {
    return location.pathname.includes(itemPath);
  };

  const isMainMenuActive = (itemPath) => {
    if (itemPath === "/") {
      return location.pathname === itemPath;
    }

    // For other menu items, check if the current location pathname starts with the item path
    return location.pathname.startsWith(itemPath);
  };

  useEffect(() => {
    dispatch(fetchConversations({}));
  }, [dispatch]);

  const handleChatClose = () => {
    setChatOpen(false);
    // navigateToFirstItem();
  };

  // Generate menu options based on conversations
  const menuOptions = conversations?.conversations?.map((conversation) => ({
    label: conversation.chat_name, // Set label as the conversation's uuid
    path: `/chatbot/my-conversation/${conversation.uuid}`, // Set path accordingly
    icon: <RiDeleteBin5Fill />,
    uuid: conversation?.uuid,
  }));

  useEffect(() => {
    if (conversations?.conversations?.length === 0) {
      setChatOpen(true);
    } else {
      setChatOpen(false);
    }
  }, [conversations]);

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setUuid(id);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    // navigateToFirstItem();
  };

  const deleteNavigateToFirst = () => {
    setOpen(false);
    navigateToFirstItem();
  };

  const navigateToFirstItem = () => {
    const firstItem = menuOptions[0];
    if (firstItem) {
      navigate(firstItem.path);
    }
  };
  const isAdmin = getuserData()?.isAdmin;

  const AllMenuOptions = [
    {
      label: "Main Menu",
      path: "/",
      icon: showDashboardSubMenu ? <ExpandMore /> : <ExpandLess />,
      // Submenu options for Dashboard
      subMenu: isAdmin
        ? [
            {
              label: "Dashboard",
              path: "/dashboard",
            },
            {
              label: "Essay Practice",
              path: "/exams",
            },
            {
              label: "Grammar and Rules",
              path: "/grammar",
            },
            {
              label: "Users",
              path: "/users",
            },
            {
              label: "Settings",
              path: "/settings",
            },
            {
              label: "Available Plans",
              path: "/my-plan",
            },
            // {
            //   label: "Privacy Policy",
            //   path: "/privacy-policy",
            // },
            // {
            //   label: "Terms & Conditions",
            //   path: "/terms-of-service",
            // },

            // Add more submenu options as needed
          ]
        : [
            {
              label: "Dashboard",
              path: "/dashboard",
            },
            {
              label: "Essay Practice",
              path: "/exams",
            },
            {
              label: "Grammar and Rules",
              path: "/grammar",
            },
            // {
            //   label: "My Plan",
            //   path: "/my-plan",
            // },
            // {
            //   label: "Privacy Policy",
            //   path: "/privacy-policy",
            // },
            // {
            //   label: "Terms & Conditions",
            //   path: "/terms-of-service",
            // },
            // Add more submenu options as needed
          ],
    },
    {
      label: "OpenEssayMe Tutor",
      path: "/chatbot-support",
      icon: <ExpandMore />,
    },
  ];

  const filteredMenuOptions = isAdmin
    ? AllMenuOptions
    : AllMenuOptions.filter((item) =>
        ["Main Menu", "OpenEssayMe Tutor"].includes(item.label)
      );

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        className="sidebar-wrapper sidebar-menu-open"
        classes={{
          paper: "custom-paper-class",
        }}
        style={{ visibility: "unset", transform: "none" }}
      >
        <Box className="list-wrap">
          <List className="sidebar" style={{ gap: "0px" }}>
            {filteredMenuOptions.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  onClick={() => {
                    // console.log(item.label);
                    if (item.label === "Main Menu") {
                      setShowDashboardSubMenu(!showDashboardSubMenu);
                    } else if (item.label !== "OpenEssayMe Tutor") {
                      handleNavigate(item.path);
                    }
                  }}
                  className={isMainMenuActive(item.path) ? "activemenu" : ""}
                  style={{ height: "48px" }}
                >
                  <ListItemButton>
                    {/* {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>} */}
                    <ListItemText
                      primary={item.label}
                      className="menu-text-wrap"
                    />
                    {item.label === "Main Menu" && (
                      <ListItemIcon
                        // className={`${
                        //   item.label === "OpenEssayMe Tutor"
                        //     ? "icon-wrap essay"
                        //     : "icon-wrap"
                        // }`}
                        className="icon-wrap"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDashboardSubMenu(!showDashboardSubMenu);
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    {item.label === "OpenEssayMe Tutor" && (
                      <ListItemIcon
                        className="icon-wrap"

                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   setShowDashboardSubMenu(!showDashboardSubMenu);
                        // }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
                {item.label === "Main Menu" && (
                  <Collapse
                    in={showDashboardSubMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subMenu.map((subItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          onClick={() => {
                            if (
                              subItem.label === "Privacy Policy" ||
                              subItem.label === "Terms & Conditions"
                            ) {
                              openInNewTab(subItem.path);
                            } else {
                              handleNavigate(subItem.path);
                            }
                          }}
                          className={
                            isMainMenuActive(subItem.path) ? "activemenu" : ""
                          }
                          style={{ height: "48px" }}
                        >
                          <ListItemButton>
                            <ListItemText
                              primary={subItem.label}
                              className="menu-text-wrap"
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
            {menuOptions?.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => handleNavigate(item.path)}
                className={isMenuActive(item.path) ? "activemenu" : ""}
                style={{ height: "38px" }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={item.label}
                    className="menu-text-wrap"
                  />
                  <ListItemIcon
                    className="icon-wrap"
                    onClick={() => handleDeleteOpen(item?.uuid)}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <List className="sidebar" style={{ marginTop: "20px" }}>
            <ListItem
              style={{ height: "20px" }}
              onClick={() => {
                openInNewTab("/privacy-policy");
              }}
            >
              <ListItemButton>
                <ListItemText
                  primary={"Privacy Policy"}
                  className="menu-text-wrap s-14"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              style={{ height: "20px" }}
              onClick={() => {
                openInNewTab("/terms-of-service");
              }}
            >
              <ListItemButton>
                <ListItemText
                  primary={"Terms & Conditions"}
                  className="menu-text-wrap s-14"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <DeleteConversationModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
        uuid={uuid}
        deleteNavigateToFirst={deleteNavigateToFirst}
      />
      <CreateChatModal
        open={chatOpen}
        setOpen={setChatOpen}
        handleDeleteClose={handleChatClose}
      />
    </>
  );
};

export default ChatSideMenu;
