import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import moment from "moment";

const ResponseFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const examDataById = useSelector((state) => state.examById.exam);
  const [examDataById, setExamDataById] = useState();
  // eslint-disable-next-line no-unused-vars
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
      question: "", // Set initial values from stored credentials
      response: "",
      feedback: "",
      marks: "",
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exam-attempt/view/${id}/`,
      });
      setExamDataById(response);
      // console.log(response);
      if (response?.attempt?.feedback_status === "done") {
        setFeedbackDone(true);
        setIsLoading(false);
      } else {
        setTimeout(fetchData, 2000); // Call fetchData again after 2 seconds
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      // name: examDataById?.
      question: examDataById?.attempt?.question || "",
      response: examDataById?.attempt?.response || "",
      feedback: examDataById?.feedback || "",
      marks: examDataById?.marks || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  return (
    <>
      <Box className="content-header-title">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            className="action-btn"
            onClick={() =>
              navigate(`/exams/${examDataById?.attempt?.exam_id}/view`)
            }
            startIcon={<IoMdArrowRoundBack className="icon-font" />}
          ></Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" component="h2">
              View Practice Attempted&nbsp;(
              {moment(examDataById?.attempt?.created_at).format("MM-DD-YYYY")})
            </Typography>
            {formik.values.marks && (
              <Typography variant="h2" component="h2" mar>
                Marks: {formik.values.marks}
              </Typography>
            )}
            {examDataById?.marks === 0 && (
              <Typography variant="h2" component="h2" mar>
                Marks: 0
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={12}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    Give Openessayme a moment to review, will provide you a
                    feedback shortly.....
                  </Typography>
                  <CircularProgress style={{ marginTop: "10px" }} />
                </Box>
              ) : (
                <Box
                  variant="div"
                  component="div"
                  className="user-detail attempt-exam"
                >
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Name of Exam
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.name}
                      </Typography>
                    </Box>
                  </Grid> */}

                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Question
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          <ReactMarkdown>
                            {formik.values.question}
                          </ReactMarkdown>
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid> */}
                    <Grid item xs={12} md={12} mt={2}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Response entered by you
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          <ReactMarkdown>
                            {formik.values.response}
                          </ReactMarkdown>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Feedback
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          {formik.values.feedback}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Marks
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          {formik.values.marks}
                        </Typography>
                      </Box>
                    </Grid> */}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default ResponseFeedback;
