import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchConversations = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchConversationStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/chatbot/list-my-conversations/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchConversationSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchConversationFailure(error.response.data));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  conversations: [],
  status: "idle",
  error: null,
};

const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    fetchConversationStart: (state) => {
      state.status = "loading";
    },
    fetchConversationSuccess: (state, action) => {
      state.status = "succeeded";
      state.conversations = action.payload;
    },
    fetchConversationFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  fetchConversationStart,
  fetchConversationSuccess,
  fetchConversationFailure,
} = conversationSlice.actions;

export default conversationSlice.reducer;
