import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";

export const fetchUserDashboard = () => async (dispatch) => {
  try {
    dispatch(fetchUserDashboardStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/dashboard`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchUserDashboardSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchUserDashboardFailure(error.response.data));
  }
};

const initialState = {
  dashboard: [],
  status: "idle",
  error: null,
};

const userDashboardSlice = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    fetchUserDashboardStart: (state) => {
      state.status = "loading";
    },
    fetchUserDashboardSuccess: (state, action) => {
      state.status = "succeeded";
      state.dashboard = action.payload;
    },
    fetchUserDashboardFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  fetchUserDashboardStart,
  fetchUserDashboardSuccess,
  fetchUserDashboardFailure,
} = userDashboardSlice.actions;

export default userDashboardSlice.reducer;
