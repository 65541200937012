import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

export const deleteExamById = (examId, token) => async (dispatch) => {
  try {
    dispatch(deleteExamStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/exams/delete/${examId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteExamSuccess(response.data));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteExamFailure(error.response.data));
  }
};

const initialState = {
  Exams: [],

  status: "idle",
  error: null,
};
const deleteExamSlice = createSlice({
  name: "deleteExams",
  initialState,
  reducers: {
    deleteExamStart: (state) => {
      state.status = "loading";
    },
    deleteExamSuccess: (state, action) => {
      state.status = "succeeded";
      state.Exams = action.payload;
    },
    deleteExamFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { deleteExamStart, deleteExamSuccess, deleteExamFailure } =
  deleteExamSlice.actions;

export default deleteExamSlice.reducer;
