export function capitalizeMessage(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function formatString(str) {
  if (str == null) {
    // Check for null or undefined
    return "UNSUBSCRIBED";
  }
  return str?.replace(/_/g, " ")?.toUpperCase();
}

const planMapping = {
  tutor_monthly: "WritePro Premium Tutor Monthly",
  intermediate_monthly: "WritePlus Intermediate Monthly",
  basic_monthly: "WriteWay Basic Monthly",
  tutor_six_monthly: "WritePro Premium Tutor Half Yearly",
  intermediate_six_monthly: "WritePlus Intermediate Half Yearly",
  basic_six_monthly: "WriteWay Basic Half Yearly",
  tutor_yearly: "WritePro Premium Tutor Yearly",
  intermediate_yearly: "WritePro Premium Tutor Yearly",
  basic_yearly: "WriteWay Basic Yearly",
};
export const getPlanName = (subscription) => {
  if (subscription && planMapping[subscription]) {
    return planMapping[subscription];
  } else {
    return "No Active Plan";
  }
};

// convert \\n to \n
export const convertDoubleBackslashToNewline = (str) => {
  return str.replace(/\\n/g, "\n");
};
