import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { GrFormViewHide } from "react-icons/gr";
import { fetchGrammar } from "../../../store/slices/grammar/grammarSlice";
import DeleteGrammarModal from "./deleteGrammarModal";
import getuserData from "../../../utils/userData";
import Loader from "../../../common/loader";

const GrammarList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const grammar = useSelector((state) => state.grammar.grammar);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  useEffect(() => {
    dispatch(fetchGrammar({}));
  }, [dispatch]);

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const isAdmin = getuserData()?.isAdmin;
  const { isLoading } = useSelector((state) => state.loader);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header" height="35px">
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              disableRipple
              onClick={() => navigate("/grammar/create")}
            >
              <Typography variant="body1" component="p">
                Create Grammar Rule
              </Typography>
            </Button>
          </Box>

          <Box className="content-layout" marginTop={isAdmin ? "24px" : "32px"}>
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              <TableContainer className="table-listing">
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Rule Name</TableCell>
                      {/* <TableCell align="left">Added On</TableCell> */}
                      {/* <TableCell align="left">Feedback Prompt</TableCell> */}
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {grammar?.grammar_rules?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={2}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          className="no-msg"
                        >
                          Grammar Rules Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      grammar?.grammar_rules?.map((exam) => (
                        <TableRow
                          key={exam.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left">{exam.name}</TableCell>
                          {/* <TableCell align="left">
                        {moment(exam?.created_at).format("MM-DD-YYYY")}
                      </TableCell> */}
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell
                            align="left"
                            // sx={{ padding: "16px 16px 16px 0px !important" }}
                            style={{
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/grammar/${exam?.id}/view`)
                              }
                              title="View Grammar"
                            >
                              <GrFormViewHide
                                className="icon-font"
                                style={{ marginBottom: "5px" }}
                              />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/grammar/${exam?.id}/edit`)
                              }
                              title="Edit Grammar"
                            >
                              <MdModeEditOutline className="icon-font" />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() => handleDeleteOpen(exam?.id)}
                              title="Delete Grammar"
                            >
                              <RiDeleteBin5Fill className="icon-font" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
      <DeleteGrammarModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
        id={id}
      />
    </>
  );
};

export default GrammarList;
