import React from "react";
import { Paper, Box } from "@mui/material";

function Item(props) {
  return (
    <>
      <Paper style={{ position: "relative" }}>
        <img src={props.item.image} alt="hero" className="herobanner" />
        <Box
          className="dark-overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
      </Paper>
    </>
  );
}

export default Item;
