import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    signupStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    signupSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    signupFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { signupStart, signupSuccess, signupFailure } =
  signupSlice.actions;

export default signupSlice.reducer;

//API CALL
export const signUp = (credentials) => async (dispatch) => {
  try {
    dispatch(signupStart());
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/signup`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch(signupSuccess(data));
    return { success: true, message: data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(signupFailure(error.response.data.message));

    return { success: false, error: error.response.data.message };
  }
};
