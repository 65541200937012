import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchSubscriptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchSubscriptionStart());
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/subscription/list-plans/`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchSubscriptionSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchSubscriptionFailure(error.response.data));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  subscriptions: [],
  status: "idle",
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    fetchSubscriptionStart: (state) => {
      state.status = "loading";
    },
    fetchSubscriptionSuccess: (state, action) => {
      state.status = "succeeded";
      state.subscriptions = action.payload;
    },
    fetchSubscriptionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  fetchSubscriptionStart,
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
