import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setUserRole } from "../role/userRoleSlice";
import { setUserSubscription } from "../subscription/userSubscriptionSlice";
// import { setLoading } from "../loader/loaderSlice";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
  userRole: "",
  userSubscription: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;

//API CALL
export const login = (credentials) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(loginStart());
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/login`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch(loginSuccess(data));
    const userData = {
      firstName: data?.first_name,
      lastName: data?.last_name,
      userName: data?.username,
      userId: data?.id,
      isAdmin: data?.is_admin,
      profileImg: data?.profile_picture_url,
      activePlan: data?.active_plan,
      isFreeTrialActive: data?.is_trial_active,
    };
    const userRole = data?.is_admin;
    const userSubscription = data?.active_plan;
    Cookies.set("accessToken", data?.access);
    Cookies.set("refreshToken", data?.refresh);
    Cookies.set("userData", JSON.stringify(userData));

    dispatch(setUserRole(userRole));
    dispatch(setUserSubscription(userSubscription));
    return { success: true, message: data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(loginFailure(error.response.data.message));

    return { success: false, error: error.response.data.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
