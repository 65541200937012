import { AppBar, Chip, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Button, Menu, MenuItem } from "@mui/material";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { getRefreshToken } from "../utils/token";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/authentication/logoutSlice";
import getuserData from "../utils/userData";
import Cookies from "js-cookie";
import { setSubscriptionFromApi } from "../store/slices/subscription/selectors";
import { setRoleFromApi } from "../store/slices/role/selectors";
import ChangePasswordModal from "../pages/auth/changePassword";
import { useCookies } from "react-cookie";

const ChatbotHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const isUserMenuOpen = Boolean(userMenuAnchorEl);
  const [cookies] = useCookies();
  const subscription = useSelector(setSubscriptionFromApi);
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState(getuserData());
  useEffect(() => {
    // This effect will run whenever cookies change
    const updatedCookieUserData = getuserData();
    setUserData(updatedCookieUserData);
  }, [cookies]);
  const isAdmin = useSelector(setRoleFromApi);
  const freeTrial = userData?.isFreeTrialActive;
  const planMapping = {
    tutor_monthly: "WritePro Premium Tutor Monthly",
    intermediate_monthly: "WritePlus Intermediate Monthly",
    basic_monthly: "WriteWay Basic Monthly",
    tutor_six_monthly: "WritePro Premium Tutor Half Yearly",
    intermediate_six_monthly: "WritePlus Intermediate Half Yearly",
    basic_six_monthly: "WriteWay Basic Half Yearly",
    tutor_yearly: "WritePro Premium Tutor Yearly",
    intermediate_yearly: "WritePro Premium Tutor Yearly",
    basic_yearly: "WriteWay Basic Yearly",
  };
  let plan = subscription;
  if (freeTrial === true) {
    plan = "FREE TRIAL";
  } else if (plan) {
    plan = planMapping[subscription]?.toUpperCase();
  } else {
    plan = "UNSUBSCRIBED";
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleUserMenuClick = (event) => {
  //   setUserMenuAnchorEl(event.currentTarget);
  // };

  // const handleUserMenuClose = () => {
  //   setUserMenuAnchorEl(null);
  // };

  const token = Cookies.get("accessToken");
  const refreshToken = getRefreshToken();
  const submitHandler = async () => {
    try {
      await dispatch(logout(token, refreshToken));
      handleClose();
      Cookies.remove("accessToken");
      navigate("/home", { replace: true });
    } catch (error) {}
  };

  const handleDeleteClose = () => {
    setModalOpen(false);
  };

  const userName = userData?.userName;
  const firstName = userData?.firstName;
  const lastName = userData?.lastName;
  const profileImage = userData?.profileImg;
  const firstLetter = userName ? userName.charAt(0) : "";
  if (!token) return null;

  return (
    <>
      <AppBar
        position="fixed"
        // open={openSideMenu}
        className={`navbar`}
      >
        <Toolbar>
          <Box
            variant="div"
            component="div"
            className="navbar-left"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              style={{ cursor: "pointer", height: "40px", width: "40px" }}
            />

            <Typography
              variant="h3"
              noWrap
              component="h3"
              style={{ color: "white", fontSize: "20px" }}
            >
              OPENESSAYME
            </Typography>
          </Box>

          <Box variant="div" component="div" style={{ marginLeft: "32px" }}>
            <Typography variant="h1" noWrap component="h1">
              OpenEssayMe Tutor
            </Typography>
          </Box>
          <Box variant="div" component="div" className="navbar-right">
            {/* <Box variant="div" component="div" className="options-menu">
            <Menu
              id="basic-menu"
              className="user-info-menu more-options"
              anchorEl={userMenuAnchorEl}
              open={isUserMenuOpen}
              onClose={handleUserMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
               
              }}
            >
              <MenuItem
                onClick={() => navigate("/exams")}
                style={{ justifyContent: "space-between" }}
              >
                <span>Essay Practice</span>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/grammar")}
                style={{ justifyContent: "space-between" }}
              >
                <span>Grammar and Rules</span>
              </MenuItem>
              {isAdmin ? (
                <MenuItem
                  onClick={() => navigate("/users")}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Users</span>
                </MenuItem>
              ) : null}
              {isAdmin ? (
                <MenuItem
                  onClick={() => navigate("/settingss")}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Settings</span>
                </MenuItem>
              ) : null}
            </Menu>
          </Box> */}
            <Box variant="div" component="div" className="user-info">
              {isAdmin ? null : (
                <Chip
                  label={plan}
                  style={{
                    color: "white",
                    backgroundColor: "#43acef",
                    height: "35px",
                    cursor: "pointer",
                  }}
                  className="subscribe"
                  onClick={() => navigate("/my-plan")}
                />
              )}
              {profileImage ? (
                <Avatar src={profileImage}></Avatar>
              ) : (
                <Avatar>{firstLetter}</Avatar>
              )}{" "}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disableRipple
                style={{ textTransform: "initial" }}
              >
                <span className="mr-1" mr={2}>
                  {firstName && lastName ? (
                    <>
                      {firstName}&nbsp;{lastName}
                    </>
                  ) : (
                    userName
                  )}
                </span>
              </Button>
              <Menu
                id="basic-menu"
                className="user-info-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  // style: {
                  //   width: buttonWidth,
                  //   boxShadow:
                  //     "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 1px rgba(0, 0, 0, 0.12)",
                  // },
                }}
              >
                <MenuItem
                  // onClick={submitHandler}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setModalOpen(true);
                    handleClose();
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Change Password</span>
                  {/* <RiLogoutCircleLine className="icon-font" /> */}
                </MenuItem>
                <MenuItem
                  onClick={submitHandler}
                  style={{
                    justifyContent: "space-between",
                    // height: "36px",
                    // paddingLeft: "16px",
                    width: "100%",
                  }}
                >
                  <span>Logout</span>
                  {/* <RiLogoutCircleLine className="icon-font" /> */}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ChangePasswordModal
        open={modalOpen}
        setOpen={setModalOpen}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};
export default ChatbotHeader;
