/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetchExams } from "../../store/slices/exams/examSlice";
import { GrFormViewHide } from "react-icons/gr";
import { fetchUsers } from "../../store/slices/users/userSlice";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";
import { getPlanName } from "../../utils/helper";
import Loader from "../../common/loader";
import { setLoading } from "../../store/slices/loader/loaderSlice";

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [apiCallFinished, setApiCallFinished] = useState(false);

  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/users/list`,
      });
      setUsers(response);
    } catch (error) {
      console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes(
          "You are not authorized to access this resource."
        )
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onAvatarMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const onAvatarMouseLeave = () => {
    setHoveredItemId(null);
  };

  const { isLoading } = useSelector((state) => state.loader);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box className="content-layout" marginTop="32px">
          <Box
            variant="div"
            component="div"
            className="tab-wrapper user-listing"
          >
            <TableContainer className="table-listing">
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow className="head-row">
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Users</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Current Plan</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users?.users?.length === 0 ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        colSpan={13}
                        rowspan={1}
                        className="no-msg"
                      >
                        Users Not found
                      </TableCell>
                    </TableRow>
                  ) : (
                    users?.users?.map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="left">
                          <Avatar
                            src={`${user?.profile_picture_url}`}
                            onMouseEnter={() => onAvatarMouseEnter(user?.id)}
                            onMouseLeave={onAvatarMouseLeave}
                            style={{
                              transform:
                                hoveredItemId === user?.id
                                  ? "scale(1.3)"
                                  : "scale(1)",
                              margin: "5px",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {user.first_name}&nbsp;
                          {user.last_name}
                        </TableCell>
                        <TableCell align="left">{user?.email}</TableCell>
                        <TableCell align="left">{user?.username}</TableCell>
                        <TableCell align="left">
                          {getPlanName(user?.active_plan)}
                        </TableCell>
                        {/* <TableCell
                      align="left"
                      // sx={{ padding: "16px 16px 16px 0px !important" }}
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <Button
                        className="listing-action-btn"
                        onClick={() => navigate(`/users/${user?.id}/view`)}
                      >
                        <GrFormViewHide
                          className="icon-font"
                          style={{ marginBottom: "5px" }}
                        />
                      </Button>
                    </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserList;
