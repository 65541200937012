import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExams } from "../../../store/slices/exams/examSlice";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { GoAlertFill } from "react-icons/go";
import Loader from "../../../common/loader";

const UsersExamList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exams.exams);

  const subscription = useSelector(setSubscriptionFromApi);
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "basic_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "basic_six_monthly",
    "basic_yearly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const isSubscribed =
    subscription && listOfPlans.some((plan) => subscription.includes(plan));
  const { isLoading } = useSelector((state) => state.loader);

  useEffect(() => {
    if (!isCheckingSubscription && isSubscribed) {
      dispatch(fetchExams({}));
    }
  }, [dispatch, isSubscribed, isCheckingSubscription]);

  useEffect(() => {
    // Simulate fetching subscription status
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  return (
    <>
      {isLoading || isCheckingSubscription ? (
        <Loader />
      ) : (
        <Box className="content-layout" marginTop="32px">
          <Box
            variant="div"
            component="div"
            className="tab-wrapper user-listing"
          >
            {isSubscribed ? (
              <TableContainer className="table-listing">
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Exam Name</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {exams?.exams?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={2}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          className="no-msg"
                        >
                          Exams Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      exams?.exams?.map((exam) => (
                        <TableRow
                          key={exam.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/exams/${exam?.id}/view`)}
                        >
                          <TableCell align="left">{exam?.name}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box className="access-denied-found">
                <Box className="page-inner">
                  <GoAlertFill
                    fontSize="65px"
                    style={{ marginBottom: "40px" }}
                  />
                  <Typography
                    variant="h3"
                    component="h3"
                    style={{ textTransform: "initial" }}
                  >
                    <Box>
                      Looks like you haven’t subscribed to the Essay Practice
                      Module yet.
                    </Box>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <Box>
                      Please subscribe to join our vibrant community of
                      learners!
                    </Box>
                  </Typography>

                  <Box style={{ height: "35px" }}>
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn add-btn"
                      disableRipple
                      onClick={() => navigate(`/my-plan`)}
                    >
                      View Plans
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default UsersExamList;
