import Cookies from "js-cookie";

const getuserData = () => {
  // const userData = JSON.parse(localStorage.getItem("token"));
  const storedUserData = Cookies.get("userData");

  // Check if userData is available
  if (!storedUserData) {
    return null;
  }
  // Parse the retrieved string back into an object
  const parsedUserData = JSON.parse(storedUserData);

  // Now, you can work with the parsed userData object
  return parsedUserData;
};

export default getuserData;
