import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const attemptGrammarSlice = createSlice({
  name: "attemptGrammar",
  initialState,
  reducers: {
    attemptGrammarStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    attemptGrammarSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    attemptGrammarFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  attemptGrammarStart,
  attemptGrammarSuccess,
  attemptGrammarFailure,
} = attemptGrammarSlice.actions;

export default attemptGrammarSlice.reducer;

export const attemptGrammar = (examData, token) => async (dispatch) => {
  try {
    dispatch(attemptGrammarStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/grammar-practice/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(attemptGrammarSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(attemptGrammarFailure(error.message));

    return { success: false, error: error.response.message };
  }
};
