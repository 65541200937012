import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import getuserData from "../../utils/userData";
import { getToken } from "../../utils/token";

const FreeTrialPaymentSuccess = () => {
  const userId = getuserData()?.userId;
  const token = getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchUserById(userId, token));
    }, 3000);
  }, [dispatch, token, userId]);

  return (
    <Box className="content-layout" marginTop="32px">
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box className="access-denied-found">
          <Box className="page-inner">
            <svg
              viewBox="0 0 24 24"
              class="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="#B0DC4C"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <Typography
              variant="h3"
              component="h3"
              style={{ textTransform: "initial", padding: "0 40px" }}
            >
              {/* <Box>Payment done!</Box> */}
              <Box style={{ marginTop: "8px" }}>
                Thanks for choosing our free trial.
              </Box>
              <Box style={{ marginTop: "8px" }}>
                You've joined a dynamic community of learners and adventurers,
                with unrestricted access for the next 7 days
              </Box>
              <Typography variant="body1" component="p" marginTop="8px">
                If you decide to cancel or alter your plan during this period,
                your complimentary trial will conclude.
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FreeTrialPaymentSuccess;
