import Cookies from "js-cookie";

export const getToken = () => {
  const UserToken = Cookies.get("accessToken");

  if (UserToken === undefined || UserToken === null) {
    return null;
  }
  return UserToken;
};

export const getRefreshToken = () => {
  const refresh = Cookies.get("refreshToken");
  if (refresh === undefined || refresh === null) {
    return null;
  }
  return refresh;
};
