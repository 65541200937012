import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";

const MessageModal = ({ handleDeleteClose, open, setOpen, id }) => {
  //   const onDeleteExam = async () => {
  //     try {
  //       const response = await dispatch(deleteExamById(id, token));

  //       if (response?.success) {
  //         successToast(response?.message || "Exam Deleted Successfully");
  //         setOpen(false);
  //         dispatch(fetchExams({}));
  //       } else {
  //         console.error("Failed! Something went wrong.");
  //         setOpen(false);
  //       }
  //     } catch (error) {
  //       console.error("API call error:", error);
  //     }
  //   };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleDeleteClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              {/* <Typography variant="h3" component="h3" className="modal-title">
                {`Are you sure you want to delete this exam ?`}
              </Typography> */}
              <Typography variant="body1" component="p">
                Requested Chat is being opened in new tab
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            {/* <Box
              variant="div"
              component="div"
              className="footer"
              // style={{ height: "35px" }}
            >
              <Box
                variant="div"
                component="div"
                className="modal-btn-group"
                style={{ height: "35px" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={onDeleteExam}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setOpen(false)}
                >
                  No
                </Button>
              </Box>
            </Box> */}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MessageModal;
