import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from '../loaderSlice';

const initialState = {
  onEditSetting: [],
  status: "idle",
  error: null,
};

const editSettingSlice = createSlice({
  name: "editSetting",
  initialState,
  reducers: {
    updateSettingStart: (state) => {
      state.status = "loading";
    },
    updateSettingSuccess: (state, action) => {
      state.status = "succeeded";
      state.onEditSetting = action.payload;
    },
    updateSettingFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  updateSettingStart,
  updateSettingSuccess,
  updateSettingFailure,
} = editSettingSlice.actions;

export const editSetting = (examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(updateSettingStart());
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/settings/edit`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(updateSettingSuccess(response));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateSettingFailure(error.response.data));
  } finally {
    // dispatch(setLoading(false));
  }
};

export default editSettingSlice.reducer;
