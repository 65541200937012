// userRolesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSubscription: null,
};

const userSubscriptionSlice = createSlice({
  name: "userSubscription",
  initialState,
  reducers: {
    setUserSubscription: (state, action) => {
      state.userSubscription = action.payload;
    },
  },
});

export const { setUserSubscription } = userSubscriptionSlice.actions;
export default userSubscriptionSlice.reducer;
