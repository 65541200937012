import "./styles/global.scss";
import "./styles/exams.scss";
import "./styles/chatbot.scss";
import "./styles/subscription.scss";
import "./styles/policy.scss";
import "./styles/landing.scss";
import Routes from "../src/routes/index";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { getToken } from "./utils/token";
import { useEffect } from "react";
import { fetchUserById } from "./store/slices/users/userByIdSlice";
import getuserData from "./utils/userData";

function App() {
  const dispatch = useDispatch();
  const token = getToken();
  const userId = getuserData()?.userId;
  useEffect(() => {
    if (token) {
      dispatch(fetchUserById(userId, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);
  return (
    // <div className="App">
    <>
      <BrowserRouter>
        {/* {isLoading && <Loader />} */}
        <ToastContainer />
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
