import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  conversation: null,
  status: "idle",
  error: null,
};

const conversationsByIdSlice = createSlice({
  name: "conversationsById",
  initialState,
  reducers: {
    fetchConversationsByIdStart: (state) => {
      state.status = "loading";
    },
    fetchConversationsByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.conversation = action.payload.data;
    },
    fetchConversationsByIdFail: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  fetchConversationsByIdStart,
  fetchConversationsByIdSuccess,
  fetchConversationsByIdFail,
} = conversationsByIdSlice.actions;

export default conversationsByIdSlice.reducer;

export const fetchConversationsById = (id, token) => async (dispatch) => {
  try {
    dispatch(fetchConversationsByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/chatbot/view-my-conversation/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchConversationsByIdSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchConversationsByIdFail(error.data));
  } finally {
    // dispatch(setLoading(false));
  }
};
