import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const changeSubscriptionSlice = createSlice({
  name: "changeSubscription",
  initialState,
  reducers: {
    changeSubscriptionStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    changeSubscriptionSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    changeSubscriptionFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  changeSubscriptionStart,
  changeSubscriptionSuccess,
  changeSubscriptionFailure,
} = changeSubscriptionSlice.actions;

export default changeSubscriptionSlice.reducer;

export const changeSubscription = (examData) => async (dispatch) => {
  const token = getToken();
  try {
    // dispatch(setLoading(true));
    dispatch(changeSubscriptionStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscription/change-plan/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(changeSubscriptionSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(changeSubscriptionFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
