import React from "react";
import { Chip, Box } from "@mui/material";

const ExamCarousel = () => {
  return (
    <Box className="slider" marginTop="-10px">
      <Box className="slide-track2" sx={{ display: "flex", gap: "15px" }}>
        <Chip label="GED" />
        <Chip label="SAT" />
        <Chip label="GRE" />
        <Chip label="ACT" />
        <Chip label="GMAT" />
        <Chip label="Advanced Placement (AP) Exams" />
        <Chip label="College Admissions" />
        <Chip label="Personal Statements" />
        <Chip label="A-Levels" />
        <Chip label="GED" />
        <Chip label="SAT" />
        <Chip label="GRE" />
        <Chip label="ACT" />
        <Chip label="GMAT" />
        <Chip label="Advanced Placement (AP) Exams" />
        <Chip label="College Admissions" />
        <Chip label="Personal Statements" />
        <Chip label="A-Levels" sx={{ bgcolor: "green.500", color: "white" }} />
      </Box>
    </Box>
  );
};

export default ExamCarousel;
