import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../store/slices/authentication/forgetPasswordSlice";
import { successToast } from "./../../response/successToast";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email address"),
});

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(forgetPassword(values))
        .then((response) => {
          if (response.success) {
            successToast(
              response?.message ||
                "Reset Password link sent successfully to your email"
            );
            navigate("/login");

            // If "Remember Me" is checked, store the credentials
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-img-wrap">
            <Box
              variant="div"
              component="div"
              className="logo-wrap header-logo"
            >
              <img
                src={logo}
                alt="logo-main"
                loading="lazy"
                className="auth-image"
              />
              <Typography
                variant="h3"
                noWrap
                component="h3"
                style={{ color: "white" }}
              >
                OPENESSAYME
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-main-wrap ">
            <Box variant="div" component="div" className="login-form-wrap p-24">
              <form onSubmit={formik.handleSubmit}>
                <Box variant="div" component="div" className="title">
                  <Typography variant="h3" component="h3">
                    Forgot Password
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{ fontSize: "14px" }}
                  >
                    Enter your email and we will send you a link to reset your
                    password
                  </Typography>
                </Box>

                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                        Email Id
                        {/* <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography> */}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic email"
                        label=""
                        variant="outlined"
                        placeholder="Enter your email"
                        size="small"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email && formik.errors.email ? (
                            <span className="error-message">
                              {formik.errors.email}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box style={{ marginTop: "28px", height: "35px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="auth-btn btn"
                    disableRipple
                    type="submit"
                    // style={{ marginTop: "28px" }}
                    // onClick={() => navigate("/dashboard")}
                  >
                    {" "}
                    Submit
                  </Button>
                </Box>
              </form>
              <Box
                variant="div"
                component="div"
                className="link-btn-wrap"
                style={{ marginTop: "10px" }}
              >
                <Button
                  className="link-btn"
                  disableRipple
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Back to Login
                </Button>
              </Box>
            </Box>
            <Box className="login-footer">
              <Box display="flex" justifyContent="canter" gap="26px">
                <Box
                  className="link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home Page
                </Box>
                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </Box>
                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/terms-of-service");
                  }}
                >
                  Terms & Conditions
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgetPassword;
