import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import "./index.css";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setUserSubscription } from "../../store/slices/subscription/userSubscriptionSlice";
import { setUserRole } from "../../store/slices/role/userRoleSlice";

const { REACT_APP_API_URL } = process.env;

const SocialAuth = () => {
  let location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGogglelogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googleLoginHandler = (code) => {
    return axios
      .get(`${REACT_APP_API_URL}/users/auth/google/${code}`)
      .then((res) => {
        localStorage.setItem("goggleFirstName", res.data.user.first_name);
        return res.data;
      })
      .catch((err) => {
        setError(err);
        return err;
      });
  };

  const onGogglelogin = async () => {
    const response = await googleLoginHandler(location.search);
    // console.log(response);
    const userData = {
      firstName: response?.user?.first_name,
      lastName: response?.user?.last_name,
      userName: response?.user?.username,
      userId: response?.user?.id,
      isAdmin: response?.is_admin,
      profileImg: response?.profile_picture_url,
      activePlan: response?.active_plan,
      isFreeTrialActive: response?.is_trial_active,
    };
    const userRole = response?.is_admin;
    const userSubscription = response?.active_plan;
    dispatch(setUserRole(userRole));
    dispatch(setUserSubscription(userSubscription));
    if (response.access_token) {
      Cookies.set("accessToken", response.access_token);
      Cookies.set("refreshToken", response.refresh_token);
      Cookies.set("userData", JSON.stringify(userData));
      navigate("/");
    }
  };

  return (
    <div className="loading-icon-container">
      <div className="loading-icon">
        <div className="loading-icon__circle loading-icon__circle--first"></div>
        <div className="loading-icon__circle loading-icon__circle--second"></div>
        <div className="loading-icon__circle loading-icon__circle--third"></div>
        <div className="loading-icon__circle loading-icon__circle--fourth"></div>
      </div>
      <small className=" text-center mr-2">Just a moment</small>
    </div>
  );
};

export default SocialAuth;
