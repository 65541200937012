import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import pagenotfound from "../assets/images/PageNotFound.png";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="not-found">
        <Box className="page-inside">
          <img
            src={pagenotfound}
            alt="logo-main"
            loading="lazy"
            className="page-404"
          />
          {/* <Typography variant="h3" component="h3" className="not-found-title">
            404 - PAGE NOT FOUND
          </Typography> */}
          <Typography variant="body1" component="p">
            Uh-oh! The page you're seeking is off the grid. Return to the
            homepage to get back on track
          </Typography>

          <Button
            variant="contained"
            size="large"
            className="primary-btn"
            disableRipple
            onClick={() => navigate(`/`)}
            style={{ lineHeight: "1.3rem" }}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
