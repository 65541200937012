import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import ViewGramQuestionModal from "./viewGramQuestionModal";
import { errorToast } from "../../../response/errorToast";

const ViewGrammar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grammar, setGrammar] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [promptLoading, setPromptLoading] = useState(true);
  // const [promptQuestion, setPromptQuestion] = useState("");
  const handleQuestionOpen = (id, response) => {
    setOpen(true);
    setSelectedQuestion(response);
  };

  const handleQuestionClose = () => {
    setOpen(false);
  };

  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
      explanation_prompt: "",
      exam_prompt: "",
      feedback_prompt: "",
      question_type: "",
      question_count: "",
    },
  });
  const question = process.env.REACT_APP_QUESTION_COUNT;
  // console.log(typeof parseInt(question));
  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/grammar/view/${id}/`,
      });
      setGrammar(response);
      // if (response?.data?.grammar_rule_definitions?.length >= 3) {
      //   setIsLoading(false);
      // } else {
      //   setTimeout(fetchData, 10000);
      // }
      // if (response?.data?.practice_sets?.length >= 3) {
      //   setPromptLoading(false);
      // } else {
      //   setTimeout(fetchData, 10000);
      // }
      const isGrammarLoaded =
        response?.data?.grammar_rule_definitions?.length >= parseInt(question);
      const isPracticeSetsLoaded =
        response?.data?.practice_sets?.length >= parseInt(question);

      if (isGrammarLoaded) {
        setIsLoading(false);
      }

      if (isPracticeSetsLoaded) {
        setPromptLoading(false);
      }

      if (!isGrammarLoaded || !isPracticeSetsLoaded) {
        setTimeout(fetchData, 10000);
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes(
          "Grammar rule with given id does not exist"
        )
      ) {
        navigate("*");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: grammar?.data?.name || "",
      explanation_prompt: grammar?.data?.explanation_prompt || "",
      exam_prompt: grammar?.data?.exam_prompt || "",
      feedback_prompt: grammar?.data?.feedback_prompt || "",
      question_type: grammar?.data?.question_type || "",
      question_count: grammar?.data?.question_count || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grammar]);
  return (
    <>
      <Box className="content-header-title">
        <Button className="action-btn" onClick={() => navigate(`/grammar`)}>
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          View Grammar Rule
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={12}>
              <Box variant="div" component="div" className="user-detail">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Grammar Rule Name
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Question Type
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.question_type === "mcq"
                          ? "MCQ"
                          : "Short Answers"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Question Count
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.question_count}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Explanation Prompt
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                        justify="center"
                      >
                        {" "}
                        {formik.values.explanation_prompt}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Exam Prompt
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {" "}
                        {formik.values.exam_prompt}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Feedback Prompt
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {" "}
                        {formik.values.feedback_prompt}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Grid container>
        <Grid item xs={12} md={6} lg={6} marginTop="0px"> */}
      <Box className="content-layout" marginTop="0px">
        <TableContainer className="table-listing">
          <Table
            // sx={{ minWidth: 579 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow className="head-row">
                <TableCell align="left">Explanations</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow className="no-msg">
                  <TableCell
                    style={{
                      width: "100%",
                      // textAlign: "center",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="no-msg"
                  >
                    Explanations are being generated in background&nbsp;
                    <CircularProgress
                      style={{
                        height: "25px",
                        width: "25px",
                        marginTop: "5px",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                grammar?.data?.grammar_rule_definitions?.map((exam, index) => (
                  <TableRow
                    key={exam.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleQuestionOpen(exam?.id, exam?.explanation)
                    }
                  >
                    <TableCell
                      align="left"
                      key={exam?.id}
                      colSpan={2}
                    >{`Explanation ${index + 1}`}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* </Grid> */}

      {/* <Grid item xs={12} md={6} lg={6} marginTop="0px"> */}
      <Box className="content-layout" marginTop="0px">
        <TableContainer className="table-listing">
          <Table
            // sx={{ minWidth: 579 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow className="head-row">
                <TableCell align="left">Practice Sets</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {promptLoading ? (
                <TableRow className="no-msg">
                  <TableCell
                    style={{
                      width: "100%",
                      // textAlign: "center",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="no-msg"
                  >
                    Practice sets are being generated in background&nbsp;
                    <CircularProgress
                      style={{
                        height: "25px",
                        width: "25px",
                        marginTop: "5px",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                grammar?.data?.practice_sets?.map((set, setIndex) => {
                  return (
                    <TableRow
                      key={set.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/grammar/${set?.id}/practice-set`);
                      }}
                    >
                      <TableCell align="left">
                        Practice Set {`${setIndex + 1}`}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}

              {/* )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* </Grid> */}
      {/* </Grid> */}
      <ViewGramQuestionModal
        open={open}
        setOpen={setOpen}
        handleQuestionClose={handleQuestionClose}
        //   examDataById={examDataById}
        selectedQuestion={selectedQuestion}
      />
    </>
  );
};
export default ViewGrammar;
