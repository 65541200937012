import { Box, Fade, Modal, Typography, TextField, Grid } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { successToast } from "../../response/successToast";
import { useDispatch } from "react-redux";
import { getToken } from "../../utils/token";
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from "../../store/slices/authentication/changePasswordSlice";

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Please enter password"),
  new_password: Yup.string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter")
    .matches(/\d/, "Password must contain at least one number")
    .test(
      "not-entirely-numeric",
      "Password must not be entirely numeric",
      (value) => isNaN(value)
    ),
  confirm_password: Yup.string()
    .required("Please re-enter password")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const ChangePasswordModal = ({ handleDeleteClose, open, setOpen, id }) => {
  const token = getToken();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      old_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        new_password: values.new_password,
        old_password: values.old_password,
      };
      dispatch(changePassword(payload, token))
        .then((response) => {
          if (response.success) {
            successToast("Password changed successfully");
            handleDeleteClose();
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleDeleteClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper" style={{ maxWidth: "550px" }}>
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Change Password
              </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box variant="div" component="div" className="body">
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        Current Password
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter Current Password"
                        size="small"
                        type="password"
                        name="old_password"
                        value={formik.values.old_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.old_password &&
                          Boolean(formik.errors.old_password)
                        }
                        helperText={
                          formik.touched.old_password &&
                          formik.errors.old_password ? (
                            <span className="error-message">
                              {formik.errors.old_password}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        New Password
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter New Password"
                        size="small"
                        type="password"
                        name="new_password"
                        value={formik.values.new_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.new_password &&
                          Boolean(formik.errors.new_password)
                        }
                        helperText={
                          formik.touched.new_password &&
                          formik.errors.new_password ? (
                            <span className="error-message">
                              {formik.errors.new_password}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        Confirm Password
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Confirm Password"
                        size="small"
                        type="password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirm_password &&
                          Boolean(formik.errors.confirm_password)
                        }
                        helperText={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password ? (
                            <span className="error-message">
                              {formik.errors.confirm_password}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box
                  variant="div"
                  component="div"
                  className="modal-btn-group"
                  style={{ height: "35px" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    type="submit"
                    //   onClick={submitHandler}
                  >
                    {" "}
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleModalClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
