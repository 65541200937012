import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { fetchUserDashboard } from "../../store/slices/dashBoardSlice";
import { useDispatch, useSelector } from "react-redux";
import getuserData from "../../utils/userData";
import { useNavigate } from "react-router-dom";

// const isAdmin = getuserData()?.isAdmin;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state.userDashboard.dashboard);
  useEffect(() => {
    dispatch(fetchUserDashboard({}));
  }, [dispatch]);

  const examDataCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px 16px",
              paddingBottom: "8px !important",
              height: "68px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest Exams
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/exams");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {dashboardData?.latest_exams?.length === 0 ? (
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={2}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Exams Not Found
                    </TableCell>
                  </TableRow>
                ) : (
                  dashboardData?.latest_exams?.map((exam) => (
                    <TableRow
                      key={exam.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/exams/${exam?.id}/view`)}
                    >
                      <TableCell align="left">{exam.name}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
  const grammarDataCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px",
              paddingBottom: "8px !important",
              height: "68px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest Grammar Rules
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/grammar");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {dashboardData?.latest_grammar_rules?.length === 0 ? (
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Grammar Not Found
                    </TableCell>
                  </TableRow>
                ) : (
                  dashboardData?.latest_grammar_rules?.map((exam) => (
                    <TableRow
                      key={exam.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/grammar/${exam?.id}/view`)}
                    >
                      <TableCell align="left">{exam.name}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const isAdmin = getuserData()?.isAdmin;

  return (
    <>
      {isAdmin ? (
        <Box className="content-wrapper">
          <Box className="card-wrapper">
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className="card-title"
                  sx={{ marginBottom: "16px" }}
                >
                  {/* {isAdmin ? "Dashboard" : "User Dashboard"} */}
                  Dashboard
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box className="content-wrapper" style={{ paddingBottom: "0px" }}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container spacing={3} style={{ flex: "1" }}>
                <Grid item xs={12}>
                  {examDataCard}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container spacing={3} style={{ flex: "1" }}>
                <Grid item xs={12}>
                  {grammarDataCard}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
