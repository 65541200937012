import React from "react";
import { Box } from "@mui/material";
// import Header from "../layout/header";
import ChatSideMenu from "./sidebar";
import getuserData from "../utils/userData";
import ChatbotHeader from "./chatbotHeader";

const ChatLayout = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const isAdmin = getuserData()?.isAdmin;
  return (
    <Box>
      <ChatbotHeader />
      <ChatSideMenu />
      <main className="main-content full-width">{children}</main>
    </Box>
  );
};

export default ChatLayout;
