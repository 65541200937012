import React from "react";
import { Box } from "@mui/material";
// import Header from "./header";
import Header from "./header";
import SideMenu from "./sidemenu";

const Layout = ({ children }) => {
  // const isAdmin = getuserData()?.isAdmin;
  const currentYear = new Date().getFullYear();

  return (
    <Box>
      <Header />
      <SideMenu />
      <main
        className="main-content full-width"
        style={{ minHeight: "calc(100vh - 150px)" }}
      >
        {children}
      </main>
      <Box className="footer-class" sx={{ padding: "16px" }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          color="var(--textgrey)"
          marginTop="auto"
        >
          Copyright © OpenEssayMe {currentYear}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
