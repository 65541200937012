import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";

const PublicRouteWrapper = ({ children }) => {
  const token = Cookies.get("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/dashboard", { replace: true }); // Redirect to home if authenticated
    }
  }, [token, navigate]);

  return token ? null : children; // Render children only if not authenticated
};

export default PublicRouteWrapper;
