import { Box, Grid, Typography, List, ListItem } from "@mui/material";
import React, { cloneElement } from "react";
import logo from "../../assets/images/logo.png";
import Footer from "./Footer";

const TermsCondition = () => {
  const addNumbersToListItems = (list) => {
    return cloneElement(list, {
      children: React.Children.map(list.props.children, (child, index) => {
        if (child.type === ListItem) {
          return cloneElement(child, {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{
                    fontWeight: "bold",
                    marginRight: "8px",
                  }}
                  className="link-color"
                >
                  {index + 1}.
                </Typography>
                <Box>{child.props.children}</Box>
              </Box>
            ),
          });
        }
        return child;
      }),
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1c2536", // Set your desired background color here
          minHeight: "100vh", // Ensure it covers the full height of the viewport
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "1000px", margin: "auto" }}>
          <Box className="policy-content-header-title">
            <Box class="setting-icon">
              <img src={logo} alt="app-logo" loading="lazy" />
            </Box>
            <Typography variant="h2" component="h2" className="link-color">
              TERMS AND CONDITIONS
            </Typography>
          </Box>
          <Box className="policy-content-layout">
            <Box variant="div" component="div" className="createuser-wrap">
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            <Typography className="policy-item">
                              Effective Date: June 01, 2024
                            </Typography>
                            <Typography
                              className="policy-item"
                              // style={{ marginTop: "10px" }}
                            >
                              Welcome to OpenEssayMe! By accessing or using our
                              website, services, software, and content provided
                              through or in connection with the service
                              (collectively, "Services"), you agree to be bound
                              by these Terms and Conditions. Please read them
                              carefully.
                            </Typography>
                            {addNumbersToListItems(
                              <List>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Acceptance of Terms
                                  </Typography>
                                  <Typography className="policy-item">
                                    By registering for and/or using the Services
                                    in any manner, including visiting or
                                    browsing the site, you agree to these Terms
                                    and Conditions and all other operating
                                    rules, policies, and procedures that may be
                                    published from time to time on the site by
                                    OpenEssayMe, each of which is incorporated
                                    by reference and each of which may be
                                    updated from time to time without notice to
                                    you.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Eligibility
                                  </Typography>
                                  <Typography className="policy-item">
                                    You must be at least 13 years old to use the
                                    Services. By agreeing to these Terms, you
                                    represent and warrant that you are at least
                                    13. If you are under the age of 18, you
                                    affirm that you have the consent of your
                                    parent or guardian to use the Services.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Registration
                                  </Typography>
                                  <Typography className="policy-item">
                                    To access some features of the Services, you
                                    must register for an account. When you
                                    register, you agree to provide accurate,
                                    current, and complete information as
                                    requested and to update such information
                                    promptly after any changes. Each
                                    registration is for a single user only,
                                    unless otherwise expressly provided on the
                                    registration page.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    User Conduct
                                  </Typography>{" "}
                                  <Typography className="policy-item">
                                    {" "}
                                    You are responsible for all activities
                                    conducted through your account. You agree to
                                    use the Services only for lawful purposes
                                    and in a way that does not infringe the
                                    rights of, restrict, or inhibit anyone
                                    else's use and enjoyment of the Services.
                                    Prohibited behavior includes harassing or
                                    causing distress or inconvenience to any
                                    other user, transmitting obscene or
                                    offensive content, or disrupting the normal
                                    flow of dialogue within the Services.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Intellectual Property
                                  </Typography>
                                  <Typography className="policy-item">
                                    {" "}
                                    All content provided on the Services,
                                    including but not limited to text, graphics,
                                    logos, images, and software, is the property
                                    of OpenEssayMe or its licensors and is
                                    protected by copyright and other
                                    intellectual property laws. You agree not to
                                    reproduce, duplicate, copy, sell, resell or
                                    exploit any portion of the Services, without
                                    express written permission by us.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Payment
                                  </Typography>
                                  <Typography className="policy-item">
                                    {" "}
                                    If you purchase any services that we offer
                                    for a fee, such as subscriptions, you agree
                                    to OpenEssayMe storing your payment card
                                    information. You also agree to pay the
                                    applicable fees for the services (including,
                                    without limitation, periodic fees for
                                    ongoing subscriptions) as they become due
                                    plus all related taxes.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Cancellation and Termination
                                  </Typography>
                                  <Typography className="policy-item">
                                    {" "}
                                    You are free to stop using the Services at
                                    any time. We also reserve the right to
                                    suspend or end the Services at any time at
                                    our discretion and without notice. For
                                    example, we may suspend or terminate your
                                    use of the Services if you are not complying
                                    with these Terms, or use the Services in a
                                    manner that would cause us legal liability,
                                    disrupt the Services or disrupt others' use
                                    of the Services.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Disclaimer of Warranties
                                  </Typography>
                                  <Typography className="policy-item">
                                    The Services are provided "as is" and "as
                                    available" without any warranties of any
                                    kind, including that the Services will
                                    operate error-free or that the Services,
                                    their servers, or the content are free of
                                    computer viruses or similar contamination or
                                    destructive features.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Limitation of Liability
                                  </Typography>
                                  <Typography className="policy-item">
                                    {" "}
                                    OpenEssayMe shall not be liable for any
                                    direct, indirect, incidental, special,
                                    consequential, or exemplary damages,
                                    including but not limited to, damages for
                                    loss of profits, goodwill, use, data, or
                                    other intangible losses, resulting from the
                                    use or the inability to use the Services.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    General Terms
                                  </Typography>
                                  <Typography className="policy-item">
                                    These Terms are governed by the laws of the
                                    State [Your State] without regard to its
                                    conflict of law provisions. Our failure to
                                    enforce any right or provision of these
                                    Terms will not be considered a waiver of
                                    those rights. If any provision of these
                                    Terms is held to be invalid or unenforceable
                                    by a court, the remaining provisions of
                                    these Terms will remain in effect.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Changes
                                  </Typography>
                                  <Typography className="policy-item">
                                    We reserve the right, at our sole
                                    discretion, to modify or replace these Terms
                                    at any time. What constitutes a material
                                    change will be determined at our sole
                                    discretion.
                                  </Typography>
                                </ListItem>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="link-color-head"
                                  >
                                    Contact Us
                                  </Typography>
                                  <Typography className="policy-item">
                                    If you have any questions about these Terms,
                                    please contact us at&nbsp;
                                    <a
                                      href="mailto:support@openessayme.com"
                                      className="link-color"
                                    >
                                      support@openessayme.com
                                    </a>
                                  </Typography>
                                </ListItem>
                              </List>
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default TermsCondition;
