import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchUsersStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchUsersSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchUsersFailure(error.response.data.errors));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  users: [],
  status: "idle",
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchUsersStart: (state) => {
      state.status = "loading";
    },
    fetchUsersSuccess: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    fetchUsersFailure: (state, action) => {
      state.status = "failed";
      if (Array.isArray(action.payload)) {
        state.error = action.payload[0];
      } else {
        state.error = action.payload.message;
      }
    },
  },
});

export const { fetchUsersStart, fetchUsersSuccess, fetchUsersFailure } =
  userSlice.actions;

export default userSlice.reducer;
