import { Box, Typography, Tab, Tabs, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptions } from "../../store/slices/subscription/subscriptionSlice";
import { useNavigate } from "react-router-dom";
import { convertDoubleBackslashToNewline } from "../../utils/helper";
import ReactMarkdown from "react-markdown";
import Cookies from "js-cookie";

const HomeSubscription = () => {
  const token = Cookies.get("accessToken");
  const [value, setValue] = useState(0);
  const [type, setType] = useState("monthly_plans");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );

  useEffect(() => {
    dispatch(fetchSubscriptions({}));
  }, [dispatch]);

  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "monthly_plans";
      case 1:
        return "six_monthly_plans";
      default:
        return "yearly_plans";
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(getTabValueString(newValue));
  };
  // console.log(type, "type");
  const tabStyles = (isSelected) => ({
    borderRadius: "5px !important",
    backgroundColor: isSelected
      ? "#43acef !important"
      : "transparent !important",
    color: isSelected ? "white !important" : "black !important",
    transition: "background-color 0.4s, color 0.4s",
    margin: "3px !important",
  });

  const SubscriptionBox = ({
    title,
    price,
    pricepermonth,
    description,
    strikethrough_amount,
  }) => {
    const descriptionWithNewlines =
      convertDoubleBackslashToNewline(description);
    return (
      <Box
        sx={{
          mx: "auto",
          padding: 2,
        }}
        className="home-sub-card"
      >
        <Typography variant="h6">
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="price-rate">{price}</Typography>
              <Typography>Plan Price</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} className="pl-0">
              <Typography
                display="flex"
                className="border-left"
                justifyContent="center"
                alignItems="center"
                gap="5px"
              >
                <Typography>
                  {" "}
                  <s>{strikethrough_amount}</s>
                </Typography>

                <Typography className="price-rate ">{pricepermonth}</Typography>
              </Typography>
              <Typography className="border-left">Price Per Month</Typography>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="h5" component="div" mt={2} className="price-title">
          {title}
        </Typography>

        <Typography style={{ whiteSpace: "pre-wrap" }}>
          <ReactMarkdown>{descriptionWithNewlines}</ReactMarkdown>
        </Typography>
        <Box mt={2} style={{ height: "35px" }}>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => {
              token ? navigate("/my-plan") : navigate("/login");
            }}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="subscription-layout center">
      <Typography className="home-title">Subscriptions</Typography>
      <Typography className="home-tagline">What Our Plans Include</Typography>
      <Box
        // sx={{
        //   width: "100%",
        //   display: "flex",
        //   justifyContent: "center",
        // }}
        className="subscription-handler"
        mt={3}
      >
        <Box
          // sx={{
          //   border: "1px solid #000", // Adjust the border color as needed
          //   borderRadius: "5px", // Optional: for rounded corners
          //   minWidth: "461px",
          // }}
          className="subscription-options"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            className="home-tab"
          >
            <Tab label="MONTHLY" sx={tabStyles(value === 0)} />
            <Tab label="SIX MONTHLY" sx={tabStyles(value === 1)} />
            <Tab label="YEARLY" sx={tabStyles(value === 2)} />
          </Tabs>
        </Box>
      </Box>
      <Grid container spacing={3} justifyContent="center" mt={3}>
        {subscriptions?.plans?.[type]?.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <SubscriptionBox
              title={plan.product.name}
              price={plan.amount}
              description={plan.product.description}
              pricepermonth={plan.amount_per_month}
              strikethrough_amount={plan.strikethrough_amount}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomeSubscription;
