import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

const initialState = {
  exam: null,
  status: "idle",
  error: null,
};

const examByIdSlice = createSlice({
  name: "examById",
  initialState,
  reducers: {
    fetchExamByIdStart: (state) => {
      state.status = "loading";
    },
    fetchExamByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.exam = action.payload.data;
    },
    fetchExamByIdFail: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { fetchExamByIdStart, fetchExamByIdSuccess, fetchExamByIdFail } =
  examByIdSlice.actions;

export default examByIdSlice.reducer;

export const fetchExamById = (id, token) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchExamByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchExamByIdSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchExamByIdFail(error.data));
  } finally {
    dispatch(setLoading(false));
  }
};
