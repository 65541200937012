import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { editExam } from "../../../store/slices/exams/editExamByIdSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ header: [1, 2, 3, 4, 5, 6] }],
//     ["link", "image"],
//   ],
// };

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

const AddInstructions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [examDataById, setExamDataById] = useState();
  const token = getToken();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      exam_prompt: "",
      feedback_prompt: "",
      description: "",
      instructions: "",
    },
    onSubmit: (values) => {
      dispatch(editExam(id, values, token))
        .then((response) => {
          if (response.success) {
            successToast("Exam updated successfully");
            navigate("/exams");
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
    } catch (error) {
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    }
  };

  useEffect(() => {
    // if (error) {
    //   navigate("*");
    // }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: examDataById?.data?.name || "",
      exam_prompt: examDataById?.data?.exam_prompt || "",
      feedback_prompt: examDataById?.data?.feedback_prompt || "",
      description: examDataById?.data?.description || "",
      instructions: examDataById?.data?.instructions || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const handleInstructionsChange = (value) => {
    formik.setFieldValue("instructions", value);
  };

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  return (
    <>
      <Box className="content-header-title">
        <Button className="action-btn" onClick={() => navigate(`/exams`)}>
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          Exam Information
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Name of Exam
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          {formik.values.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Exam Information
                        </Typography>
                        <SunEditor
                          setContents={formik.values.instructions}
                          onChange={handleInstructionsChange}
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              // ['paragraphStyle', 'blockquote'],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list"],
                              ["outdent", "indent"],

                              ["table", "horizontalRule", "link", "image"],
                            ],
                            defaultTag: "div",
                            minHeight: "400px",
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                        />
                        {/* <ReactQuill
                          value={formik.values.instructions}
                          onChange={handleInstructionsChange}
                          // onBlur={formik.handleBlur}
                          modules={modules}
                        /> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box
              variant="div"
              component="div"
              className="footer-btn"
              style={{ marginTop: "16px", height: "35px" }}
            >
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  type="submit"
                  disableRipple
                >
                  {" "}
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default AddInstructions;
