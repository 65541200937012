import "react-toastify/dist/ReactToastify.css";
import { capitalizeMessage } from "../utils/helper";
import { toast } from "react-toastify";

export const successToast = (message) => {
  toast.success(capitalizeMessage(message), {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    className: "toast",
  });
};
