import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  settings: null,
  status: "idle",
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    fetchSettingsStart: (state) => {
      state.status = "loading";
    },
    fetchSettingsSuccess: (state, action) => {
      state.status = "succeeded";
      state.settings = action.payload;
      state.error = null; // Reset error when fetching succeeds
    },
    fetchSettingsFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  fetchSettingsStart,
  fetchSettingsSuccess,
  fetchSettingsFailure,
} = settingsSlice.actions;

export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(fetchSettingsStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/settings/view`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchSettingsSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchSettingsFailure(error.message));
  }
};

export default settingsSlice.reducer;
