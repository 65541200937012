import React from "react";
import { Box, Typography } from "@mui/material";

const HomeFooter = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box className="home-footer">
      <Box>
        <Typography className="color-white">
          © OpenEssayMe all rights reserved
        </Typography>
      </Box>
      <Box className="footer-content">
        <Typography
          className="color-white footer-link"
          onClick={() => {
            openInNewTab("/privacy-policy");
          }}
        >
          Privacy Policy
        </Typography>
        <Typography className="color-white">|</Typography>
        <Typography
          className="color-white footer-link"
          onClick={() => {
            openInNewTab("/terms-of-service");
          }}
        >
          Terms & Condition
        </Typography>
      </Box>
    </Box>
  );
};

export default HomeFooter;
