import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children, path }) => {
  const token = Cookies.get("accessToken");
  const isLogoutRoute = path === "/logout"; // Add this line

  return !isLogoutRoute && !token ? (
    <Navigate to="/" replace={true} />
  ) : (
    children
  ); // Update this line
};

export default ProtectedRoute;
