import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, Fade } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptions } from "../../store/slices/subscription/subscriptionSlice";
import { fetchSubscriptionById } from "../../store/slices/subscription/subscriptionByIdSlice";
import { createSubscription } from "../../store/slices/subscription/createSubscriptionSlice";
import { successToast } from "../../response/successToast";
import { changeSubscription } from "../../store/slices/subscription/changeSubscriptionSlice";
import { setSubscriptionFromApi } from "../../store/slices/subscription/selectors";
import Loader from "../../common/loader";
import { setRoleFromApi } from "../../store/slices/role/selectors";
import ReactMarkdown from "react-markdown";
import { convertDoubleBackslashToNewline } from "../../utils/helper";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import getuserData from "../../utils/userData";
import { getToken } from "../../utils/token";

const PlanButton = ({
  period,
  selectedPeriod,
  onClick,
  currentPlan,
  activePlan,
}) => {
  // const isActive = selectedPeriod === currentPlan;
  const planSuffix = {
    monthly: "monthly_plans",
    half_yearly: "six_monthly_plans",
    yearly: "yearly_plans",
  };
  const planName = planSuffix[currentPlan];

  const isActivePlan = period === planName;
  const isSelected = selectedPeriod === period;
  // const disabledPlan = activePlan?.includes("monthly")
  //   ? "monthly_plans"
  //   : activePlan?.includes("six_monthly")
  //   ? "six_monthly_plans"
  //   : activePlan?.includes("yearly")
  //   ? "yearly_plans"
  //   : null;

  // console.log(
  //   isActivePlan,
  //   period,
  //   currentPlan,
  //   planName,
  //   activePlan
  //   // disabledPlan
  // );
  return (
    <Button
      className={`primary-btn btn ${isActivePlan ? "active green-bg" : ""} ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => onClick(period)}
    >
      {period.replace("_plans", "").replace("_", " ").toUpperCase()}
    </Button>
  );
};

const PriceCard = ({
  price,
  isMonthly,
  onSubscribe,
  priceId,
  obtainedPrice,
  activePlan,
  onChangePlan,
  subscription,
  isAdmin,
  open,
  setOpen,
  setPriceId,
}) => {
  const disabledPlan = subscription ? priceId === obtainedPrice : null;
  const descriptionWithNewlines = convertDoubleBackslashToNewline(
    price.product.description
  );
  // console.log(descriptionWithNewlines, "/n");
  // console.log(activePlan, "+++++++++++", disabledPlan, priceId, obtainedPrice);
  return (
    <Box className="price-card">
      <Typography variant="h2" component="h2">
        {price.product.name}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        style={{ whiteSpace: "pre-wrap" }}
      >
        <ReactMarkdown>{descriptionWithNewlines}</ReactMarkdown>
      </Typography>
      <Box className="price-details">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          marginTop="25px"
        >
          <Typography variant="body1" component="label" className="label">
            Plan Price:
          </Typography>
          <Typography variant="body2" component="span" className="value">
            {price.amount}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <Typography variant="body1" component="label" className="label">
            Price Per Month:
          </Typography>
          <Typography variant="body2" component="span" className="value">
            {price?.strikethrough_amount && (
              <>
                <s>{price.strikethrough_amount}</s>&nbsp;
              </>
            )}
            {price.amount_per_month}
          </Typography>
        </Box>
      </Box>
      {isAdmin ? null : (
        <Box style={{ height: "50px", marginTop: "25px" }}>
          <Button
            variant="contained"
            size="large"
            className={`primary-btn btn ${disabledPlan ? "grey-btn" : ""}`}
            type="submit"
            disableRipple
            onClick={() => {
              if (activePlan) {
                setOpen(true);
                setPriceId(price?.price_id);
              } else {
                onSubscribe(price.price_id);
              }
            }}
            disabled={disabledPlan}
          >
            {disabledPlan ? "Subscribed" : "Subscribe"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Subscription = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  const subscriptionById = useSelector(
    (state) => state.subscriptionById.subscription
  );
  const { isLoading } = useSelector((state) => state.loader);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [activePlan, setActivePlan] = useState();
  const [obtainedPrice, setObtainedPrice] = useState();
  const subscription = useSelector(setSubscriptionFromApi);
  const isAdmin = useSelector(setRoleFromApi);
  const [open, setOpen] = useState(false);
  const [priceID, setPriceId] = useState("");
  const userId = getuserData()?.userId;
  const token = getToken();
  useEffect(() => {
    dispatch(fetchSubscriptions({}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubscriptionById({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserById(userId, token));
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (subscriptionById) {
      setSelectedPeriod(subscriptionById?.subscription?.billing_cycle);
      setActivePlan(subscriptionById?.subscription?.plan_name);
      setObtainedPrice(subscriptionById?.subscription?.price_id);
    }
    setSelectedPeriod("monthly_plans");
    // setActivePlan("");
  }, [subscriptionById]);
  // console.log(activePlan, "activeplan");
  const handleSubscribe = async (priceId) => {
    const payload = {
      price_id: priceId,
    };
    try {
      const response = await dispatch(createSubscription(payload));
      if (response.success) {
        if (response.data.redirect) {
          if (response.data.new_tab) {
            // Open in a new tab
            window.open(response.data.redirect, "_blank");
          } else {
            // Open in the same tab
            window.location.href = response.data.redirect;
          }
        } else {
          // Handle other responses
          // console.log(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    }
  };

  const handleChangeSubscribe = async () => {
    const payload = {
      price_id: priceID,
    };
    try {
      const response = await dispatch(changeSubscription(payload));
      if (response.success) {
        successToast(response?.data?.message);
        setOpen(false);
        dispatch(fetchSubscriptionById({}));
        dispatch(fetchUserById(userId, token));
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
      setOpen(false);
    }
  };
  // console.log(priceID, "priceID");
  // const cancelSubscription = async () => {
  //   try {
  //     const response = await callApi({
  //       method: "post",
  //       url: `${process.env.REACT_APP_API_URL}/subscription/cancel-subscription/`,
  //     });
  //     if (response) {
  //       setOpen(false);
  //       successToast(response?.message);
  //     } else {
  //       errorToast(["Something went wrong"]);
  //       setOpen(false);
  //     }
  //   } catch (error) {
  //     console.error("Error cancelling subscription:", error.message);
  //     setOpen(false);
  //   }
  // };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const freeTrial = getuserData()?.isFreeTrialActive;
  // console.log(freeTrial, "freetrial");
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header-subscription" height="35px">
            {["monthly_plans", "six_monthly_plans", "yearly_plans"].map(
              (period) => (
                <PlanButton
                  key={period}
                  period={period}
                  selectedPeriod={selectedPeriod}
                  onClick={setSelectedPeriod}
                  currentPlan={subscriptionById?.subscription?.billing_cycle}
                  activePlan={activePlan}
                />
              )
            )}
          </Box>
          {/* <Box variant="div" component="div" className="tag-wrap">
            {" "}
            <Chip
              label="Free Trial"
              // style={{
              //   width: "100%",
              // }}
            />
          </Box> */}
          <Box className="content-wrapper-subscription">
            <Box className="card-wrapper">
              <Box className="price-cards">
                {subscriptions?.plans?.[selectedPeriod]?.map((price) => (
                  <PriceCard
                    key={price.price_id}
                    price={price}
                    isMonthly={selectedPeriod === "monthly_plans"}
                    onSubscribe={handleSubscribe}
                    priceId={price.price_id}
                    obtainedPrice={obtainedPrice}
                    activePlan={activePlan}
                    onChangePlan={handleChangeSubscribe}
                    subscription={subscription}
                    isAdmin={isAdmin}
                    open={open}
                    setOpen={setOpen}
                    setPriceId={setPriceId}
                  />
                ))}
              </Box>
            </Box>
            {/* {subscription ? (
              <Box
                variant="div"
                component="div"
                className="footer-btn"
                style={{ marginTop: "25px" }}
              >
                <Box variant="div" component="div" className="modal-btn-group">
                  <Button
                    id="submit-btn" // Added an id to the button for easier targeting
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    type="button"
                    disableRipple
                    onClick={() => setOpen(true)}
                  >
                    Cancel Subscription
                  </Button>
                </Box>
              </Box>
            ) : null} */}
          </Box>
        </>
      )}
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleDeleteClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box className={`modal-wrapper warning-popup`}>
              <Box variant="div" component="div" className="title">
                {freeTrial ? (
                  <>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="modal-title"
                    >
                      {`Are you sure you want to change your subscription?`}
                    </Typography>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="modal-title small-text"
                      style={{ marginTop: "10px" }}
                    >{`Your current complimentary trial will end if you proceed.`}</Typography>
                  </>
                ) : (
                  <Typography
                    variant="h3"
                    component="h3"
                    className="modal-title"
                  >
                    {`Are you sure you want to change your subscription?`}
                  </Typography>
                )}
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box
                variant="div"
                component="div"
                className="footer"
                // style={{ height: "35px" }}
              >
                <Box
                  variant="div"
                  component="div"
                  className="modal-btn-group"
                  style={{ height: "35px" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => handleChangeSubscribe()}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setOpen(false)}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default Subscription;
