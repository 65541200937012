import React from "react";
import {
  Box,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { RiAddFill, RiSubtractFill } from "react-icons/ri";

const FreAskedQuestion = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqs = [
    {
      question: "What types of exams can I prepare for with OpenEssayMe?",
      answer:
        "OpenEssayMe provides targeted essay writing and grammar practice for a variety of major exams, including the GED, SAT, ACT, and GRE among others. Our platform is designed to help you understand and prepare for the specific requirements of each test.",
    },
    {
      question: "How does the Online Essay Your practice work?",
      answer:
        " In our Online Essay Your Practice, you’ll receive guidance on how to construct essays tailored to the criteria of major tests. Our team of educators, who have years of experience in teaching and preparing students for exams, continuously review and update the platform to reflect the latest essay requirements and scoring guidelines. After submitting an essay, you will receive detailed feedback and a score that approximates what you might receive on the actual exam. This feedback, powered by a blend of educator expertise and advanced AI tools, highlights areas for improvement to ensure your writing meets or exceeds current test standards. With these resources, you are provided unlimited opportunities to practice and perfect your essay writing skills.",
    },
    {
      question:
        "Can I use the Online Grammar Practice even if I'm not preparing for an exam? ",
      answer:
        "Absolutely! While our grammar practice is an excellent resource for exam prep, it's also beneficial for anyone looking to improve their general writing skills. You can access a wide range of exercises and examples to help enhance your understanding and use of grammar.",
    },
    {
      question: "What kind of support does the AI Tutor provide?",
      answer:
        "The OpenEssayMe AI Tutor acts like a real teacher to provide real-time, personalized support. You can ask questions about grammar, essay writing, or exam preparation, and receive detailed explanations and guidance to help clarify your doubts and improve your understanding.",
    },
    {
      question: "How personalized is the feedback provided?",
      answer:
        " Our feedback mechanism uses advanced pedagogical strategies to offer highly personalized feedback based on your performance. It takes into account the specific exam you are preparing for and the individual aspects of writing and grammar you need to focus on, ensuring you receive actionable insights tailored to your needs.",
    },
    {
      question:
        " Is there a limit to how many practice essays or grammar exercises I can do? ",
      answer:
        "No, there is no limit. OpenEssayMe offers unlimited access to essay prompts and grammar exercises, allowing you to practice as much as you need. This feature is designed to help you continually improve your skills at your own pace.",
    },
    {
      question: "How often is the content on OpenEssayMe updated?",
      answer:
        "Our content is regularly updated to reflect the latest exam standards and educational methodologies. We strive to ensure that our resources are current and effective, providing you with the best possible preparation experience.",
    },
    {
      question: "What educational levels does OpenEssayMe cater to?",
      answer:
        "OpenEssayMe is designed to support learners from elementary and primary schools, through high school, to college and beyond. Our resources are expertly tailored to help students at any stage of their educational journey improve their essay writing and grammar skills, whether they are preparing for standardized tests or seeking to enhance their overall academic writing prowess.",
    },
    {
      question: "Is my work saved on the platform?",
      answer:
        "Yes, all your work is automatically saved on the platform. This allows you to access and review your essays and exercises anytime, track your progress, and continue improving your skills.",
    },
    {
      question: "Can I downgrade my subscription? ",
      answer: "Absolutely! You can downgrade your subscription at any time.",
    },
    {
      question:
        "Can I receive tutoring for an exam not currently listed on the platform? ",
      answer:
        "Yes, we are always expanding our offerings. If you need preparation for an exam not currently listed, please contact us. We will evaluate your needs and provide a timeline for when we can make the necessary preparations and resources available.",
    },
    {
      question: "Can I continue to use the platform after I pass my exam?",
      answer:
        "Absolutely, and we encourage it! Continuing to use the platform can help further refine your writing and analytical skills, which are valuable in academic and professional settings. Our resources are designed to support lifelong learning and continuous improvement.",
    },
    {
      question: "Is OpenEssayMe only for essays?",
      answer:
        "Yes, OpenEssayMe is currently designed to enhance your essay writing abilities, preparing you thoroughly for the essay sections of major exams. Alongside, we also focus on honing your grammar skills. Both areas are essential for academic success, helping you excel in the exams and beyond with clarity and precision.",
    },
    {
      question:
        "Are there any resources available for teachers or tutors who want to use OpenEssayMe with their students?",
      answer:
        "Yes, OpenEssayMe warmly welcomes educators to use our platform as a supplementary tool for enhancing their students' essay writing and grammatical skills. Teachers and tutors can guide their students through our extensive resources, demonstrating how to make the most of our tailored essay practices and grammar exercises. Additionally, we encourage educators to reach out to us with any specific ideas or requests they might have for integrating their teaching strategies with our platform. We are committed to collaborating with teachers to enrich the learning experience and meet educational goals effectively.",
    },
  ];

  // Inside your component or page
  <FreAskedQuestion faqs={faqs} />;

  return (
    <Box className="subscription-layout center">
      <Typography className="home-title">Frequently Asked Questions</Typography>
      <Typography className="home-tagline" mb={2}>
        Find Your Answers Here
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className={`accordian-question ${
            expanded === `panel${index}` ? "expanded-section" : ""
          }`}
          sx={{ mb: 2, width: "100%" }}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel${index}` ? <RiSubtractFill /> : <RiAddFill />
            }
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            className="accordian-question"
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              className="title-content"
            >
              <Typography variant="body1">{faq.question}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FreAskedQuestion;
