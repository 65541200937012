import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <Box className="footer-wrapper">
      <Box display="flex" justifyContent="center" gap="26px">
        <Box
          className="link"
          onClick={() => {
            navigate("/home");
          }}
        >
          Home Page
        </Box>
        <Box
          className="link"
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </Box>
        <Box
          className="link"
          onClick={() => {
            navigate("/terms-of-service");
          }}
        >
          Terms & Conditions
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" color="var(--textgrey)">
        Copyright © OpenEssayMe {currentYear}
      </Box>
    </Box>
  );
};

export default Footer;
