import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

export const deleteConversationById = (examId, token) => async (dispatch) => {
  try {
    dispatch(deleteConversationStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/chatbot/delete-conversation/${examId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteConversationSuccess(response.data));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteConversationFailure(error.response.data));
  }
};

const initialState = {
  conversation: [],
  status: "idle",
  error: null,
};
const deleteConversationSlice = createSlice({
  name: "deleteConversation",
  initialState,
  reducers: {
    deleteConversationStart: (state) => {
      state.status = "loading";
    },
    deleteConversationSuccess: (state, action) => {
      state.status = "succeeded";
      state.conversation = action.payload;
    },
    deleteConversationFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  deleteConversationStart,
  deleteConversationSuccess,
  deleteConversationFailure,
} = deleteConversationSlice.actions;

export default deleteConversationSlice.reducer;
