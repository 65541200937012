import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Modal,
  Fade,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../response/successToast";
import { getToken } from "../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import getuserData from "../../utils/userData";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import dummyImage from "../../assets/images/image4.png";
import { setRoleFromApi } from "../../store/slices/role/selectors";
import { MdModeEditOutline } from "react-icons/md";
import { editUser } from "../../store/slices/users/editUserByIdSlice";
import Loader from "../../common/loader";
import Cookies from "js-cookie";
import { getPlanName } from "../../utils/helper";
import { setSubscriptionFromApi } from "../../store/slices/subscription/selectors";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const user = useSelector((state) => state.userById.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [activePlan, setActivePlan] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [profileImg, setProfileImg] = useState();
  const [profileImgError, setProfileImgError] = useState("");
  const fileProfileInputRef = useRef();
  const [profilePictureUrl, setProfilePictureUrl] = useState(dummyImage);
  const [open, setOpen] = useState(false);
  const { isLoading } = useSelector((state) => state.loader);
  const subscription = useSelector(setSubscriptionFromApi);
  const userId = getuserData()?.userId;
  useEffect(() => {
    if (token) {
      dispatch(fetchUserById(userId, token));
    }
  }, [dispatch, token, userId]);

  const isAdmin = useSelector(setRoleFromApi);

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name || "");
      setLastName(user?.last_name || "");
      setEmail(user?.email || "");
      setActivePlan(user?.active_plan || "No Active Plan");
      setUserName(user?.username || "");
      setProfilePictureUrl(user?.profile_picture_url || dummyImage);
    }
  }, [user, isAdmin]);

  const handleProfileUploadClick = () => {
    if (fileProfileInputRef.current) {
      fileProfileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (!["jpg", "jpeg", "png"].includes(fileExtension)) {
      setProfileImgError(
        "File type not allowed. Please select a JPG, JPEG, PNG file."
      );
    } else {
      setProfileImgError("");
      setProfileImg(file);
    }
    if (file) {
      // Handle the file upload
    }
  };

  const formData = new FormData();

  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitHandler = async () => {
    let flag = 0;
    if (!firstName.trim()) {
      setFirstNameError("Please enter first name");
      flag = 1;
    }

    if (!lastName.trim()) {
      setLastNameError("Please enter last name");
      flag = 1;
    }

    if (!userName.trim()) {
      setUsernameError("Please enter username");
      flag = 1;
    }

    if (!email.trim()) {
      setEmailError("Please enter email");
      flag = 1;
    } else if (!validateEmail(email.trim())) {
      setEmailError("Please enter a valid email address");
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }

    const payload = {
      username: userName.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.trim(),
      profile_picture: profileImg,
    };
    for (const key in payload) {
      if (Object.hasOwnProperty.call(payload, key)) {
        formData.append(key, payload[key]);
      }
    }
    try {
      const response = await dispatch(editUser(formData));
      if (response.success) {
        if (profileImg) {
          const imageUrl = URL.createObjectURL(profileImg);
          setProfilePictureUrl(imageUrl);
        }
        successToast("Profile updated successfully!");
        const res = await dispatch(fetchUserById(userId, token));
        const userData = {
          firstName: res?.data?.data?.first_name,
          lastName: res?.data?.data?.last_name,
          userName: res?.data?.data?.username,
          userId: res?.data?.data?.id,
          isAdmin: res?.data?.data?.is_staff,
          profileImg: res?.data?.data?.profile_picture_url,
        };
        Cookies.set("userData", JSON.stringify(userData));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/subscription/cancel-subscription/`,
      });
      if (response) {
        setOpen(false);
        successToast(response?.message);
        dispatch(fetchUserById(userId, token));
      } else {
        errorToast(["Something went wrong"]);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error.message);
      setOpen(false);
    }
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const freeTrial = getuserData()?.isFreeTrialActive;
  // console.log(freeTrial);
  return (
    <>
      {isLoading && <Loader />}
      <Box className="content-header-title">
        <Button className="action-btn" onClick={() => navigate(`/`)}>
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          Profile
        </Typography>
      </Box>
      <Box className="content-layout no-bottom">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <Grid container spacing={4}>
            <Grid
              item
              lg={3}
              md={4}
              sm={12}
              xs={12}
              // style={{
              //     width: '100%',
              //     height: '100%',
              // }}
            >
              <Box
                variant="div"
                component="div"
                className="edit-profile-view"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  variant="div"
                  component="div"
                  className="edit-profile-picture"
                  style={{ height: "100%" }}
                >
                  <Box
                    variant="div"
                    component="div"
                    className="image-wrap"
                    style={{ height: "100%" }}
                  >
                    <Box
                      variant="div"
                      component="div"
                      className="image"
                      style={{
                        height: "100%",
                      }}
                    >
                      {/* {user?.profile_picture_url ? ( */}
                      <img
                        src={profilePictureUrl}
                        alt="app-logo"
                        loading="lazy"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                      {/* ) : (
                        <img src={dummyImage} alt="app-logo" loading="lazy" />
                      )} */}
                    </Box>
                    <Button
                      variant="outlined"
                      className="action-btn upld-btn"
                      startIcon={<MdModeEditOutline />}
                      disableRipple
                      onClick={handleProfileUploadClick}
                    ></Button>
                    <input
                      type="file"
                      ref={fileProfileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Box>
                  {profileImgError && (
                    <Typography className="error">{profileImgError}</Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={9} md={8}>
              <Box variant="div" component="div" className="user-detail">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        First Name
                      </Typography>

                      <TextField
                        className={`input-field ${
                          firstNameError && "error-border"
                        }`}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter First Name"
                        name="first_name"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value.trim());
                          if (e.target.value.trim()) {
                            setFirstNameError("");
                          }
                        }}
                      />
                      {firstNameError && (
                        <Typography className="error">
                          {firstNameError}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Last Name
                      </Typography>

                      <TextField
                        className={`input-field ${
                          lastNameError && "error-border"
                        }`}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Last Name"
                        name="last_name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value.trim());
                          if (e.target.value.trim()) {
                            setLastNameError("");
                          }
                        }}
                      />
                      {lastNameError && (
                        <Typography className="error">
                          {lastNameError}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Username
                      </Typography>

                      <TextField
                        className={`input-field ${
                          usernameError && "error-border"
                        }`}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Username"
                        name="username"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value.trim());
                          if (e.target.value.trim()) {
                            setUsernameError("");
                          }
                        }}
                      />
                      {usernameError && (
                        <Typography className="error">
                          {usernameError}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Email
                      </Typography>

                      <TextField
                        className={`input-field ${
                          emailError && "error-border"
                        }`}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value.trim());
                          if (e.target.value.trim()) {
                            setEmailError("");
                          }
                        }}
                      />
                      {emailError && (
                        <Typography className="error">{emailError}</Typography>
                      )}
                    </Box>
                  </Grid>
                  {!isAdmin ? (
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Active Plan
                        </Typography>

                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Active Plan"
                          name="name"
                          value={
                            freeTrial === true
                              ? "Free Trial"
                              : getPlanName(activePlan)
                          }
                          disabled
                        />
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box
            variant="div"
            component="div"
            className="footer-btn"
            style={{ marginTop: "16px" }}
          >
            <Box variant="div" component="div" className="btn-group">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                type="submit"
                disableRipple
                onClick={() => {
                  // checkErrors();
                  submitHandler();
                }}
              >
                {" "}
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {subscription ? (
        <Box className="margin-24">
          <Typography
            style={{ cursor: "pointer", color: "#ff8e97" }}
            onClick={() => setOpen(true)}
          >
            Cancel Subscription
          </Typography>
        </Box>
      ) : null}
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleDeleteClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box className="modal-wrapper">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3" className="modal-title">
                  {`Are you sure you want to cancel your subscription?`}
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box
                variant="div"
                component="div"
                className="footer"
                // style={{ height: "35px" }}
              >
                <Box
                  variant="div"
                  component="div"
                  className="modal-btn-group"
                  style={{ height: "35px" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => cancelSubscription()}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setOpen(false)}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};
export default Profile;
