import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createSubscriptionSlice = createSlice({
  name: "createSubscription",
  initialState,
  reducers: {
    createSubscriptionStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createSubscriptionSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createSubscriptionFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createSubscriptionStart,
  createSubscriptionSuccess,
  createSubscriptionFailure,
} = createSubscriptionSlice.actions;

export default createSubscriptionSlice.reducer;

export const createSubscription = (examData) => async (dispatch) => {
  const token = getToken();
  try {
    // dispatch(setLoading(true));
    dispatch(createSubscriptionStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscription/create-checkout/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(createSubscriptionSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(createSubscriptionFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
