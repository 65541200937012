import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import { attemptExam } from "../../../store/slices/exams/attemptExamSlice";
import { capitalizeMessage } from "../../../utils/helper";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { GoAlertFill } from "react-icons/go";
import Loader from "../../../common/loader";
import { setLoading } from "../../../store/slices/loader/loaderSlice";

const validationSchema = Yup.object().shape({
  response: Yup.string().required("Please provide your response"),
});

const AttemptExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const { isLoading } = useSelector((state) => state.loader);
  const [examDataById, setExamDataById] = useState();
  const [isAttemptLoading, setIsAttemptLoading] = useState(true);
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      exam_id: "",
      question: "",
      response: "",
      question_id: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(attemptExam(values, token))
        .then((response) => {
          if (response.success) {
            successToast("Exam submitted successfully");
            navigate(`/exams/${response?.data?.id}/exam-feedback`);
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
      if (response?.data?.exam_question === null) {
        // setFeedbackDone(true);
        setTimeout(fetchData, 10000); // Call fetchData again after 2 seconds
      } else {
        setIsAttemptLoading(false);
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    formik.setValues({
      exam_id: examDataById?.data?.exam_question?.exam_id,
      question: examDataById?.data?.exam_question?.response,
      response: "",
      question_id: examDataById?.data?.exam_question?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for some browsers
      return event.returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (remainingTime > 0) {
  //       setRemainingTime(remainingTime - 1);
  //     } else {
  //       formik.handleSubmit();
  //     }
  //   }, 1000);

  //   // Store remaining time in localStorage
  //   localStorage.setItem("remainingTime", remainingTime);

  //   return () => clearTimeout(timer);
  // }, [remainingTime, formik]);

  // useEffect(() => {
  //   // Retrieve remaining time from localStorage
  //   const storedRemainingTime = localStorage.getItem("remainingTime");
  //   if (storedRemainingTime) {
  //     setRemainingTime(parseInt(storedRemainingTime));
  //   }
  // }, []);

  const subscription = useSelector(setSubscriptionFromApi);
  // console.log(subscription, "subsciption");
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "basic_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "basic_six_monthly",
    "basic_yearly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const isSubscribed =
    subscription && listOfPlans.some((plan) => subscription.includes(plan));

  useEffect(() => {
    fetchData();
  }, [dispatch, subscription]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isSubscribed ? (
        <>
          {
            <Box className="content-header-title">
              <Button className="action-btn" onClick={() => navigate(`/exams`)}>
                <IoMdArrowRoundBack className="icon-font" />
              </Button>
              <Typography variant="h2" component="h2">
                {capitalizeMessage(examDataById?.data?.name)}&nbsp;Practice
              </Typography>
            </Box>
          }
          <Box className="content-layout">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              {isAttemptLoading ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={12} lg={12}>
                      <Box
                        variant="div"
                        component="div"
                        className="user-detail attempt-exam"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <Box variant="div" component="div">
                              {/* <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Question
                        </Typography> */}
                              <Typography
                                variant="body2"
                                component="span"
                                className="value"
                              >
                                <ReactMarkdown>
                                  {formik.values.question}
                                </ReactMarkdown>
                              </Typography>
                              <TextField
                                multiline
                                rows={20}
                                className="input-field"
                                placeholder="Enter Response"
                                name="response"
                                value={formik.values.response}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                sx={{ width: "100%" }}
                                error={
                                  formik.touched.response &&
                                  Boolean(formik.errors.response)
                                }
                                helperText={
                                  formik.touched.response &&
                                  formik.errors.response ? (
                                    <span className="error-message">
                                      {formik.errors.response}
                                    </span>
                                  ) : null
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    variant="div"
                    component="div"
                    className="footer-btn"
                    style={{ marginTop: "16px" }}
                  >
                    <Box variant="div" component="div" className="btn-group">
                      <Button
                        variant="contained"
                        size="large"
                        className="primary-btn btn"
                        type="submit"
                        disableRipple
                      >
                        {" "}
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box className="content-layout" marginTop="32px">
          <Box
            variant="div"
            component="div"
            className="tab-wrapper user-listing"
          >
            <Box className="access-denied-found">
              <Box className="page-inner">
                <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
                {/* <Typography variant="h3" component="h3">
                  Access Denied
                </Typography>
                <Typography variant="body1" component="p">
                  You are not subscribed to Essay Practice Module. Please
                  purchase atleast basic plan to access it.
                </Typography> */}

                <Typography
                  variant="h3"
                  component="h3"
                  style={{ textTransform: "initial" }}
                >
                  <Box>
                    Looks like you haven’t subscribed to the Essay Practice
                    Module yet.
                  </Box>
                </Typography>
                <Typography variant="body1" component="p">
                  <Box>
                    Please subscribe to join our vibrant community of learners!
                  </Box>
                </Typography>
                <Box style={{ height: "35px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={() => navigate(`/my-plan`)}
                  >
                    View Plans
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default AttemptExam;
