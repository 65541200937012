import React from "react";
import Carousel from "react-material-ui-carousel";
import Item from "./Item";
import image1 from "../../assets/images/heroImage.png";
import image2 from "../../assets/images/heroImage2.png";
import image3 from "../../assets/images/heroImage3.png";
import image4 from "../../assets/images/heroImage4.png";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function HeroBanner(props) {
  const navigate = useNavigate();
  const token = Cookies.get("accessToken");
  var items = [
    {
      name: "Random Name #1",
      image: image1,
    },
    {
      name: "Random Name #2",
      image: image2,
    },
    {
      name: "Random Name #3",
      image: image3,
    },
    {
      name: "Random Name #4",
      image: image4,
    },
  ];

  return (
    <>
      <Carousel
        duration={500}
        interval={4000}
        autoPlay={true}
        animation="slide"
        className="carousel-btn"
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
      <Box className="home-content">
        <Typography className="tagline sizesmall">
          From Draft to Perfect Score
        </Typography>
        <Typography className="tagline">
          Unlimited Essay Prep for Major Exams
        </Typography>
        <Typography className="home-description" mt={2}>
          OpenEssayMe provides unlimited tutoring and tailored essay practices,
          instant scoring, and personalized feedback directly aligned with
          official exam formats like the GED, SAT, ACT, GRE, and others. You
          will also have 24/7 access to our personalized AI tutor that will help
          you with any aspect of grammar and essay writing, ensuring your
          exam-ready in the shortest possible time.
        </Typography>
        <Box style={{ height: "35px", marginTop: "20px" }}>
          <Button
            variant="contained"
            size="large"
            className="header-btn btn add-btn"
            disableRipple
            sx={{ ml: 2 }}
            onClick={() => {
              token ? navigate("/my-plan") : navigate("/login");
            }}
          >
            Free Trial
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default HeroBanner;
