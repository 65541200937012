import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

export const deleteGrammarById = (examId, token) => async (dispatch) => {
  try {
    dispatch(deleteGrammarStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/grammar/delete/${examId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteGrammarSuccess(response.data));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteGrammarFailure(error.response.data));
  }
};

const initialState = {
  Grammar: [],
  status: "idle",
  error: null,
};
const deleteGrammarSlice = createSlice({
  name: "deleteGrammar",
  initialState,
  reducers: {
    deleteGrammarStart: (state) => {
      state.status = "loading";
    },
    deleteGrammarSuccess: (state, action) => {
      state.status = "succeeded";
      state.Grammar = action.payload;
    },
    deleteGrammarFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  deleteGrammarStart,
  deleteGrammarSuccess,
  deleteGrammarFailure,
} = deleteGrammarSlice.actions;

export default deleteGrammarSlice.reducer;
