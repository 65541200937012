import {
  Box,
  Fade,
  Modal,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../utils/token";
import { RiArrowDownSLine } from "react-icons/ri";
import { createConversation } from "../../store/slices/interaction/createConversationSlice";
import { useNavigate } from "react-router-dom";
import { fetchConversations } from "../../store/slices/interaction/conversationSlice";

const CreateConversationModal = ({ handleDeleteClose, open, setOpen, id }) => {
  const token = getToken();
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const [examId, setExamId] = useState("");
  const [grammarId, setGrammarId] = useState("");
  const exams = useSelector((state) => state.exams.exams);
  const grammar = useSelector((state) => state.grammar.grammar);
  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    const exam = {
      type: type,
      exam_id: examId,
    };

    const grammar = {
      type: type,
      grammar_rule_id: grammarId,
    };

    let payload;
    if (type === "exam") {
      payload = exam;
    } else {
      payload = grammar;
    }

    // eslint-disable-next-line no-unused-vars
    const response = dispatch(createConversation(payload, token))
      .then((response) => {
        if (response.success) {
          //   successToast(response.message);
          setOpen(false);
          dispatch(fetchConversations({}));
          navigate(
            `/chatbot/my-conversation/${response?.data?.conversation?.uuid}`,
            "_blank"
          );
          setType("");
          setExamId("");
          setGrammarId("");
          setSubmit(false);
        } else {
          console.error("Error creating conversation:", response.error);
          setOpen(false);
          setSubmit(false);
        }
      })
      .catch((error) => {
        console.error("Error creating conversation:", error.message);
        setOpen(false);
        setSubmit(false);
      });
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleExamChange = (event) => {
    setExamId(event.target.value);
    if (type === "exam") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  const handleGrammarChange = (event) => {
    setGrammarId(event.target.value);
    if (type === "grammar_rule") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleDeleteClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="chatbot-modal-wrapper">
            <Box variant="div" component="div" className="title">
              {/* <Typography variant="h3" component="h3" className="modal-title">
                {`Are you sure you want to delete this exam ?`}
              </Typography> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box variant="div" component="div" className="modal-dropdown">
                    <Typography
                      variant="body1"
                      component="label"
                      className="label"
                    >
                      Select your Domain
                    </Typography>

                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        value={type}
                        onChange={handleTypeChange}
                        size="small"
                        IconComponent={RiArrowDownSLine}
                        defaultValue="user"
                        className="modal-select"
                      >
                        <MenuItem className="custom-menu-item" value={"exam"}>
                          Essay Practice
                        </MenuItem>
                        <MenuItem
                          className="custom-menu-item"
                          value={"grammar_rule"}
                        >
                          Grammar and Rules
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {type === "exam" ? (
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                        style={{ marginBottom: "7px" }}
                      >
                        Select Exam
                      </Typography>

                      <FormControl fullWidth>
                        <Select
                          value={examId}
                          onChange={handleExamChange}
                          id="demo-simple-select"
                          size="small"
                          IconComponent={RiArrowDownSLine}
                          placeholder="Select your Domain"
                          className="modal-select"
                          //   label="Select your Domain"
                        >
                          {exams?.exams?.map((designation) => (
                            <MenuItem
                              className="custom-menu-item"
                              key={designation.id}
                              value={designation.id}
                            >
                              {designation.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : null}

                {type === "grammar_rule" ? (
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Select Grammar Rule
                      </Typography>

                      <FormControl
                        fullWidth
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        <Select
                          value={grammarId}
                          onChange={handleGrammarChange}
                          id="demo-simple-select"
                          size="small"
                          IconComponent={RiArrowDownSLine}
                          className="modal-select"
                        >
                          {grammar?.grammar_rules?.map((designation) => (
                            <MenuItem
                              className="custom-menu-item"
                              key={designation.id}
                              value={designation.id}
                            >
                              {designation.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box
              variant="div"
              component="div"
              className="footer"
              // style={{ height: "35px" }}
            >
              <Box
                variant="div"
                component="div"
                className="modal-btn-group"
                style={{ height: "35px" }}
              >
                {/* <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={handleSubmit}
                >
                  Create
                </Button> */}
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setOpen(false);
                    setType("");
                    setExamId("");
                    setGrammarId("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CreateConversationModal;
