import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import address from "../../assets/images/address.png";
import contact from "../../assets/images/contact.png";
import mail from "../../assets/images/mail.png";
import { useDispatch } from "react-redux";
import { contactNotification } from "../../store/slices/contact/contactSlice";
import { successToast } from "./../../response/successToast";

const validateEmail = (email) => {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const ContactUs = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submit, setSubmit] = useState(true);

  const handleSubmit = () => {
    // setSubmit(true);

    if (
      !name.trim() ||
      !email.trim() ||
      !message.trim()
      // !validateEmail(email.trim())
    ) {
      setSubmit(true);
      return;
    }

    if (!validateEmail(email.trim())) {
      setEmailError("Please enter valid email");
      return;
    }

    const payload = {
      name,
      email,
      message,
    };
    // console.log(payload);
    dispatch(contactNotification(payload))
      .then((response) => {
        if (response.success) {
          successToast("Message sent successfully!");
          setName("");
          setEmail("");
          setMessage("");
        } else {
          console.error("Error creating conversation:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating conversation:", error.message);
      })
      .finally(() => {
        setSubmit(true); // Reset button disabled state
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
      setEmailError("");
    } else if (name === "message") {
      setMessage(value);
    }

    // Enable submit button if all fields are filled
    if (name === "name" || name === "email" || name === "message") {
      setSubmit(!(name && email && message));
    }
  };

  return (
    <Box className="blue-bg max-width contact-us">
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box variant="div" component="div" className="home-textfield">
              <Typography
                variant="body1"
                component="label"
                className="label color-white"
              >
                Full Name
              </Typography>

              <TextField
                className="input-field white-placeholder"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Full Name"
                name="name"
                value={name}
                // onChange={(e) => {
                //   setName(e.target.value);
                // }}
                onChange={handleInputChange}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    color: "white", // Input text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // Label color when focused
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
            <Box variant="div" component="div" className="home-textfield">
              <Typography
                variant="body1"
                component="label"
                className="label color-white"
              >
                Email Id
              </Typography>

              <TextField
                className="input-field white-placeholder"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Email Id"
                name="email"
                value={email}
                // onChange={(e) => {
                //   setEmail(e.target.value);
                // }}
                onChange={handleInputChange}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    color: "white", // Input text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // Label color when focused
                  },
                }}
              />
            </Box>
            {emailError && (
              <Typography className="home-error-message">
                {emailError}
              </Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
            <Box variant="div" component="div" className="home-textfield">
              <Typography
                variant="body1"
                component="label"
                className="label color-white"
              >
                Message
              </Typography>

              <TextField
                multiline
                rows={3}
                className="input-field white-placeholder"
                placeholder="Enter Message"
                name="message"
                value={message}
                onChange={handleInputChange}
                // onChange={(e) => {
                //   setMessage(e.target.value);
                // }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    color: "white", // Input text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // Label color when focused
                  },
                }}
              />
            </Box>
          </Grid>
          <Box
            style={{
              height: "35px",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              size="large"
              className="home-btn btn"
              disableRipple
              type="submit"
              onClick={handleSubmit}
              disabled={submit}
            >
              {" "}
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography className="services-title">Contact Us</Typography>
          <Typography className="home-tagline color-white" mt={1}>
            Get in Touch
          </Typography>
          <Typography className="service-description" mt={1}>
            We would love to hear from you! If there are specific exams you're
            preparing for or if you have any suggestions or questions, please
            don't hesitate to reach out. We are continually updating our
            resources and services to better meet your needs, and your feedback
            is invaluable in guiding these improvements.
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <img src={address} alt="practice" />
            </Grid>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className="service-description" mt={1}>
                330-340 Baychester Ave #1105 Bronx, NY 10475
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <img src={contact} alt="practice" />
            </Grid>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className="service-description" mt={1}>
                WhatsApp Coming Soon
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <img src={mail} alt="practice" />
            </Grid>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className="service-description" mt={1}>
                <a href="mailto:support@openessayme.com" className="link-color">
                  support@openessayme.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
