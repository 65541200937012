import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ClipboardJS from "clipboard";
import { IoMdArrowRoundBack } from "react-icons/io";
import ViewQuestionModal from "./viewQuestionModal";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import { toast } from "react-toastify";

const ViewExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const examDataById = useSelector((state) => state.examById.exam);
  const [examDataById, setExamDataById] = useState();
  // const error = useSelector((state) => state.examById.error);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [quesId, setQuesId] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isCopying, setIsCopying] = useState(false);
  const clipboardButtonRef = useRef(null);
  const handleQuestionOpen = (id, response) => {
    setOpen(true);
    setQuesId(id);
    setSelectedQuestion(response);
  };

  const handleQuestionClose = () => {
    setOpen(false);
  };
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      exam_prompt: "",
      feedback_prompt: "",
    },
  });
  const question = process.env.REACT_APP_QUESTION_COUNT;
  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
      if (response?.data?.exam_questions_count >= parseInt(question)) {
        setIsLoading(false);
      } else {
        setTimeout(fetchData, 2000);
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: examDataById?.data?.name || "",
      exam_prompt: examDataById?.data?.exam_prompt || "",
      feedback_prompt: examDataById?.data?.feedback_prompt || "",
      description: examDataById?.data?.description || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const handleInfoNavigation = () => {
    const examId = examDataById?.data?.uuid; // Replace with your exam ID
    const url = `${process.env.REACT_APP_API_URL}/exam-instructions/${examId}`;
    window.open(url, "_blank");
  };

  let clipboardInstance = null;
  let isToastDisplayed = false;

  const copyLinkHandler = async () => {
    if (isCopying) {
      return;
    }
    setIsCopying(true);
    const Link = examDataById?.data?.uuid;
    const examLink = `${process.env.REACT_APP_API_URL}/exam-instructions/${Link}`;
    if (clipboardInstance) {
      clipboardInstance.destroy();
    }

    clipboardInstance = new ClipboardJS(clipboardButtonRef.current, {
      text: () => examLink,
    });

    clipboardInstance.on("success", () => {
      if (!isToastDisplayed) {
        toast.success("Copied to clipboard");
        isToastDisplayed = true;
      }
      clipboardInstance.destroy();
      setIsCopying(false);
    });

    clipboardButtonRef.current.click();
  };

  return (
    <>
      <Box className="content-header-title">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button className="action-btn" onClick={() => navigate(`/exams`)}>
            <IoMdArrowRoundBack className="icon-font" />
          </Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" component="h2">
              View Exam
            </Typography>
            <Box display="flex" gap="10px" height="35px">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={copyLinkHandler}
                ref={clipboardButtonRef}
              >
                <Typography variant="body1" component="p">
                  Copy Information Link
                </Typography>
              </Button>
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={handleInfoNavigation}
              >
                <Typography variant="body1" component="p">
                  Exam Overview
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={12}>
              <Box variant="div" component="div" className="user-detail">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Name of Exam
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Exam Prompt
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.exam_prompt}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Feedback Prompt
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.feedback_prompt}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="label"
                        className="label"
                      >
                        Description
                      </Typography>

                      <Typography
                        variant="body2"
                        component="span"
                        className="value"
                      >
                        {formik.values.description}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="content-layout">
        <TableContainer className="table-listing">
          <Table
            // sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow className="head-row">
                <TableCell align="left">Exam Prompts</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow className="no-msg">
                  <TableCell
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="no-msg"
                  >
                    Prompts are being generated in background{" "}
                    <CircularProgress
                      style={{
                        height: "25px",
                        width: "25px",
                        marginTop: "5px",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                examDataById?.data?.exam_questions?.map((exam, index) => (
                  <TableRow
                    key={exam.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleQuestionOpen(exam?.id, exam?.response)}
                  >
                    <TableCell
                      align="left"
                      key={exam?.id}
                      colSpan={2}
                    >{`Prompt ${index + 1}`}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ViewQuestionModal
        open={open}
        setOpen={setOpen}
        handleQuestionClose={handleQuestionClose}
        examDataById={examDataById}
        selectedQuestion={selectedQuestion}
      />
    </>
  );
};
export default ViewExam;
