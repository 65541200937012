import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authentication/authSlice";
// import facebook from "../../assets/images/facebook.svg";
// import FacebookLogin from "react-facebook-login";
// import Cookies from "js-cookie";
// import axios from "axios";
// import { setUserRole } from "../../store/slices/role/userRoleSlice";
// import { setUserSubscription } from "../../store/slices/subscription/userSubscriptionSlice";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter username"),
  password: Yup.string().required("Please enter password"),
});

const {
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT,
  REACT_APP_FACEBOOK_ID,
} = process.env;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: "", // Set initial values from stored credentials
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(login(values))
        .then((response) => {
          if (response.success) {
            // successToast("Login successful");
            navigate("/");

            // If "Remember Me" is checked, store the credentials
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [username, setUsername] = useState(
    localStorage.getItem("goggleFirstName")
  );

  useEffect(() => {
    const storedUsername = localStorage.getItem("user_goggle");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = new URLSearchParams({
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/google`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });

    const url = `${googleAuthUrl}?${params}`;

    window.location.href = url;
  }, []);

  // async function responseFacebook(e) {
  //   // console.log(e);
  //   const fullName = e.name;
  //   const [firstName, lastName] = fullName?.split(" ");
  //   const payload = {
  //     username: e.email,
  //     email: e.email,
  //     first_name: firstName,
  //     last_name: lastName,
  //     // profile_picture_url: e?.picture?.data?.url
  //   };
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/users/auth/facebook/`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(response);
  //     const userData = {
  //       firstName: response?.data?.user?.first_name,
  //       lastName: response?.data?.user?.last_name,
  //       userName: response?.data?.user?.username,
  //       userId: response?.data?.user?.id,
  //       isAdmin: response?.data?.is_admin,
  //       profileImg: response?.data?.profile_picture_url,
  //       activePlan: response?.data?.active_plan,
  //       isFreeTrialActive: response?.data?.is_trial_active,
  //     };
  //     const userRole = response?.data?.is_admin;
  //     const userSubscription = response?.data?.active_plan;
  //     dispatch(setUserRole(userRole));
  //     dispatch(setUserSubscription(userSubscription));
  //     Cookies.set("accessToken", response?.data?.access_token);
  //     Cookies.set("refreshToken", response?.data?.refresh_token);
  //     Cookies.set("userData", JSON.stringify(userData));
  //     navigate("/");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-img-wrap">
            <Box
              variant="div"
              component="div"
              className="logo-wrap header-logo"
            >
              <img
                src={logo}
                alt="logo-main"
                loading="lazy"
                className="auth-image"
              />
              <Typography
                variant="h3"
                noWrap
                component="h3"
                style={{ color: "white" }}
              >
                OPENESSAYME
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-main-wrap ">
            <Box variant="div" component="div" className="login-form-wrap p-24">
              <form onSubmit={formik.handleSubmit}>
                <Box variant="div" component="div" className="title">
                  <Typography variant="h3" component="h3">
                    LOGIN
                  </Typography>
                </Box>

                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                        Username
                        {/* <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography> */}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic username"
                        label=""
                        variant="outlined"
                        placeholder="Enter your username"
                        size="small"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.username &&
                          Boolean(formik.errors.username)
                        }
                        helperText={
                          formik.touched.username && formik.errors.username ? (
                            <span className="error-message">
                              {formik.errors.username}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                        Password
                        {/* <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography> */}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic password"
                        label=""
                        variant="outlined"
                        placeholder="Enter your password"
                        size="small"
                        name="password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password ? (
                            <span className="error-message">
                              {formik.errors.password}
                            </span>
                          ) : null
                        }
                      />
                      <Box variant="div" component="div" className="forgot-pw">
                        <Button
                          className="link-btn"
                          disableRipple
                          style={{ textTransform: "capitalize" }}
                          onClick={() => navigate("/signup")}
                        >
                          {" "}
                          Sign Up
                        </Button>
                        <Button
                          className="link-btn"
                          disableRipple
                          style={{ textTransform: "capitalize" }}
                          onClick={() => navigate("/forget-password")}
                        >
                          Forgot Password?
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    height: "35px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="auth-btn btn"
                    disableRipple
                    type="submit"
                  >
                    {" "}
                    Login
                  </Button>
                </Box>
              </form>

              <Box className="login-or" style={{ marginBottom: "5px" }}>
                OR
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box
                  variant="div"
                  component="div"
                  className="btn-group"
                  style={{ height: "35px" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="auth-btn btn"
                    disableRipple
                    style={{ alignItems: "center" }}
                    onClick={openGoogleLoginPage}
                  >
                    Login with Google&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="20px"
                      height="20px"
                    >
                      <path
                        fill="#FFC107"
                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                      />
                      <path
                        fill="#FF3D00"
                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                      />
                      <path
                        fill="#4CAF50"
                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                      />
                      <path
                        fill="#1976D2"
                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                      />
                    </svg>
                  </Button>
                  {/* <Button
                    variant="contained"
                    size="large"
                    className="auth-btn btn"
                    disableRipple
                    style={{ alignItems: "center" }}
                  >
                    <FacebookLogin
                      appId={REACT_APP_FACEBOOK_ID}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                    />
                    <img
                      src={facebook}
                      alt="facebook"
                      className="facebook-icon"
                      style={{ marginLeft: "5px" }}
                    />
                  </Button> */}
                  {/* <FacebookLogin
                    appId={REACT_APP_FACEBOOK_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                  /> */}
                </Box>
              </Box>
            </Box>
            <Box className="login-footer">
              <Box display="flex" justifyContent="canter" gap="26px">
                <Box
                  className="link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home Page
                </Box>
                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </Box>

                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/terms-of-service");
                  }}
                >
                  Terms & Conditions
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
