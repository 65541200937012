import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { successToast } from "../../response/successToast";
import { getToken } from "../../utils/token";
import * as Yup from "yup";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";
import { editSetting } from "../../store/slices/settings/editSettingsSlice";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  OPENAI_API_KEY: Yup.string().required("Please enter Open AI API Key"),
});

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [setting, setSetting] = useState();
  const token = getToken();
  const formik = useFormik({
    initialValues: {
      OPENAI_API_KEY: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(editSetting(values, token))
        .then((response) => {
          if (response.success) {
            successToast("Setting updated successfully");
            fetchData();
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      setSetting(response);
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes(
          "You are not authorized to access this resource."
        )
      ) {
        navigate("*");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      OPENAI_API_KEY: setting?.settings?.OPENAI_API_KEY || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  return (
    <>
      <Box className="content-layout" marginTop="32px">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Open AI API Key
                        </Typography>

                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Open AI API Key"
                          name="OPENAI_API_KEY"
                          value={formik.values.OPENAI_API_KEY}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.OPENAI_API_KEY &&
                            Boolean(formik.errors.OPENAI_API_KEY)
                          }
                          helperText={
                            formik.touched.OPENAI_API_KEY &&
                            formik.errors.OPENAI_API_KEY ? (
                              <span classOPENAI_API_KEY="error-message">
                                {formik.errors.OPENAI_API_KEY}
                              </span>
                            ) : null
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box
              variant="div"
              component="div"
              className="footer-btn"
              style={{ marginTop: "16px", height: "35px" }}
            >
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  type="submit"
                  disableRipple
                >
                  {" "}
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default Settings;
