import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Menu, Avatar } from "@mui/material";
import logo from "../../assets/images/logo.png";
import HeroBanner from "./HeroBanner";
import AboutUs from "./AboutUs";
import Services from "./Services";
import HomeSubscription from "./subscription";
import FreAskedQuestion from "./Faq";
import ContactUs from "./ContactUs";
import ExamCarousel from "./Carousel2";
import HomeFooter from "./Footer";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import getuserData from "../../utils/userData";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logout } from "../../store/slices/authentication/logoutSlice";
import { getRefreshToken } from "../../utils/token";

const drawerWidth = 240;
const navItems = [
  { label: "Home", id: "home" },
  { label: "About Us", id: "about-us" },
  { label: "Services", id: "services" },
  { label: "Pricing", id: "pricing" },
  { label: "FAQ", id: "faq" },
  { label: "Contact Us", id: "contact" },
];

export function DrawerAppBar({ scrollToSection, isScrolled }, props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Home");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cookies] = useCookies();
  const [userData, setUserData] = useState(getuserData());
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavItemClick = (item) => {
    setSelectedItem(item.label);
    scrollToSection(item.id);
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // This effect will run whenever cookies change
    const updatedCookieUserData = getuserData();
    setUserData(updatedCookieUserData);
  }, [cookies]);

  const userName = userData?.userName;
  const firstName = userData?.firstName;
  const lastName = userData?.lastName;
  const profileImage = userData?.profileImg;
  const firstLetter = userName ? userName.charAt(0) : "";

  const token = Cookies.get("accessToken");
  const refreshToken = getRefreshToken();
  const submitHandler = async () => {
    try {
      await dispatch(logout(token, refreshToken));
      handleClose();
      Cookies.remove("accessToken");
      navigate("/", { replace: true });
    } catch (error) {}
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className="mobile-nav"
    >
      <Box
        variant="h6"
        sx={{ my: 2 }}
        color="white"
        className="home-title"
        onClick={() => navigate("/")}
      >
        <img
          src={logo}
          alt="logo-main"
          loading="lazy"
          className="large-logo"
          style={{ cursor: "pointer", height: "40px", width: "40px" }}
        />
        <Typography
          variant="h3"
          noWrap
          component="h3"
          style={{ color: "white", fontSize: "20px" }}
        >
          OPENESSAYME
        </Typography>
      </Box>
      <Divider />
      <List className="home-white-font">
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              component="a"
              onClick={() => handleNavItemClick(item)}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        {!token ? (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/login")}
            >
              <ListItemText primary={"Login"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={() => navigate("/profile")}
              >
                <ListItemText primary={"Profile"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={submitHandler}
              >
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        className={`home-nav ${isScrolled ? "scrolled-nav" : ""}`}
      >
        <Toolbar className="home-toolbar">
          <IconButton
            color="white"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
            className="white-icon-button"
          >
            <MenuIcon />
          </IconButton>
          <Box
            variant="h6"
            component="div"
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              cursor: "pointer",
            }}
            className="home-title"
            onClick={() => {
              token ? navigate("/") : navigate("/login");
            }}
          >
            <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              style={{ cursor: "pointer", height: "40px", width: "40px" }}
              sx={{
                display: { xs: "none" },
              }}
            />
            <Typography
              variant="h3"
              noWrap
              component="h3"
              style={{ color: "white", fontSize: "20px" }}
            >
              OPENESSAYME
            </Typography>
          </Box>
          <Box
            sx={{ display: { xs: "none", sm: "block" } }}
            className="navigation-options"
          >
            {navItems.map((item) => (
              <Button
                className="button-text"
                key={item.label}
                sx={{
                  color: "#fff",
                  borderBottom:
                    selectedItem === item.label ? "2px solid #43ACEF" : "none",
                  fontSize: "18px",
                }}
                onClick={() => handleNavItemClick(item)}
              >
                {item.label}
              </Button>
            ))}
          </Box>
          <Box>
            {" "}
            <Box
              style={{ height: "35px" }}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              {!token ? (
                <Button
                  variant="contained"
                  size="large"
                  className="header-btn btn add-btn"
                  disableRipple
                  sx={{ ml: 2 }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </Button>
              ) : (
                <>
                  {profileImage ? (
                    <Avatar src={profileImage}></Avatar>
                  ) : (
                    <Avatar>{firstLetter}</Avatar>
                  )}
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    disableRipple
                    style={{ textTransform: "initial", color: "white" }}
                  >
                    <span className="mr-1 color-white" mr={2}>
                      {firstName && lastName ? (
                        <>
                          {firstName}&nbsp;{lastName}
                        </>
                      ) : (
                        userName
                      )}
                    </span>
                  </Button>
                </>
              )}
              <Menu
                id="basic-menu"
                className="user-info-menu home-sidemenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  style: {
                    boxShadow:
                      "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 1px rgba(0, 0, 0, 0.12)",
                    backgroundColor: "#1c2536",
                    color: "white",
                    width: "170px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Profile</span>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    setModalOpen(true);
                    handleClose();
                  }}
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <span>Change Password</span>
                </MenuItem> */}
                <MenuItem
                  onClick={submitHandler}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Logout</span>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1c2536",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

const HomePage = () => {
  const [scrolled, setScrolled] = useState(false);
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const servicesRef = useRef(null);
  const faqRef = useRef(null);
  const contactUsRef = useRef(null);
  const priceRef = useRef(null);

  const scrollToSection = (sectionId) => {
    let sectionRef = null;

    if (sectionId === "home") {
      sectionRef = homeRef;
    } else if (sectionId === "about-us") {
      sectionRef = aboutUsRef;
    } else if (sectionId === "services") {
      sectionRef = servicesRef;
    } else if (sectionId === "pricing") {
      sectionRef = priceRef;
    } else if (sectionId === "faq") {
      sectionRef = faqRef;
    } else if (sectionId === "contact") {
      sectionRef = contactUsRef;
    }

    // console.log(sectionRef);
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
      sectionRef.current.focus();
    } else {
      console.warn(`Section ref for ${sectionId} is not assigned`);
    }
  };

  const handleScroll = () => {
    const heroBannerHeight = homeRef.current
      ? homeRef.current.getBoundingClientRect().height
      : 0;
    const scrollY = window.scrollY;

    if (scrollY > heroBannerHeight / 4) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box className="homelayout">
      <DrawerAppBar scrollToSection={scrollToSection} isScrolled={scrolled} />
      <div ref={homeRef}>
        <HeroBanner ref={homeRef} id="home" />
      </div>
      <ExamCarousel />
      <div ref={aboutUsRef}>
        <AboutUs id="about-us" />
      </div>
      <div ref={servicesRef}>
        <Services id="services" />
      </div>
      <div ref={priceRef}>
        <HomeSubscription id="pricing" />
      </div>
      <div ref={faqRef}>
        <FreAskedQuestion id="faq" />
      </div>
      <div ref={contactUsRef}>
        <ContactUs id="contact" />
      </div>
      <HomeFooter />
    </Box>
  );
};

export default HomePage;
