import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/slices/authentication/resetPasswordSlice";
import { useSearchParams } from "react-router-dom";
import { successToast } from "./../../response/successToast";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter")
    .matches(/\d/, "Password must contain at least one number")
    .test(
      "not-entirely-numeric",
      "Password must not be entirely numeric",
      (value) => isNaN(value)
    ),
  password2: Yup.string()
    .required("Please re-enter password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useSearchParams();
  const token = params.get("token");
  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(resetPassword(values.password, token))
        .then((response) => {
          if (response.success) {
            successToast(response?.message || "Password reset successfully!");
            navigate("/login");

            // If "Remember Me" is checked, store the credentials
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-img-wrap">
            <Box
              variant="div"
              component="div"
              className="logo-wrap header-logo"
            >
              <img
                src={logo}
                alt="logo-main"
                loading="lazy"
                className="auth-image"
              />
              <Typography
                variant="h3"
                noWrap
                component="h3"
                style={{ color: "white" }}
              >
                OPENESSAYME
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box variant="div" component="div" className="login-main-wrap ">
            <Box variant="div" component="div" className="login-form-wrap p-24">
              <form onSubmit={formik.handleSubmit}>
                <Box variant="div" component="div" className="title">
                  <Typography variant="h3" component="h3">
                    Reset Password
                  </Typography>
                </Box>

                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                        Password
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic password"
                        label=""
                        variant="outlined"
                        placeholder="Enter your password"
                        size="small"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password ? (
                            <span className="error-message">
                              {formik.errors.password}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                        Confirm Password
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic password"
                        label=""
                        variant="outlined"
                        placeholder="Enter your password"
                        size="small"
                        type="password"
                        name="password2"
                        value={formik.values.password2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password2 &&
                          Boolean(formik.errors.password2)
                        }
                        helperText={
                          formik.touched.password2 &&
                          formik.errors.password2 ? (
                            <span className="error-message">
                              {formik.errors.password2}
                            </span>
                          ) : null
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box style={{ marginTop: "28px", height: "35px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="auth-btn btn"
                    disableRipple
                    type="submit"

                    // onClick={() => navigate("/dashboard")}
                  >
                    {" "}
                    Submit
                  </Button>
                </Box>
              </form>
              <Box
                variant="div"
                component="div"
                className="link-btn-wrap"
                style={{ marginTop: "10px" }}
              >
                <Button
                  className="link-btn"
                  disableRipple
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  Back to Login
                </Button>
              </Box>
            </Box>
            <Box className="login-footer">
              <Box display="flex" justifyContent="canter" gap="26px">
                <Box
                  className="link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home Page
                </Box>
                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </Box>
                <Box
                  className="link"
                  onClick={() => {
                    openInNewTab("/terms-of-service");
                  }}
                >
                  Terms & Conditions
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
