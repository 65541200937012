/* eslint-disable no-unused-vars */
import axios from "axios";
import store from "../store/store";
import { getToken } from "../utils/token";
// axios.defaults.withCredentials = true;

export const callApi = async (config) => {
  const { url, method, data } = config;
  const state = store.getState();
  const token = getToken();
  // const token = state.auth.token; // Assuming your auth state holds the token

  try {
    const response = await axios({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error, "error");

    throw error;
  }
};
