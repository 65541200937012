import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GoAlertFill } from "react-icons/go";

const NoAccess = () => {
  const navigate = useNavigate();

  return (
    <Box className="content-layout" marginTop="32px">
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box className="access-denied-found">
          <Box className="page-inner">
            <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
            {/* <Typography variant="h3" component="h3">
              Access Denied
            </Typography> */}
            <Typography variant="body1" component="p">
              Only users have the ability to attempt exams.
            </Typography>

            <Box style={{ height: "35px" }}>
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={() => navigate(`/`)}
              >
                Back To Dashboard
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoAccess;
