import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const startConversationSlice = createSlice({
  name: "startConversation",
  initialState,
  reducers: {
    startConversationStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    startConversationSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    startConversationFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  startConversationStart,
  startConversationSuccess,
  startConversationFailure,
} = startConversationSlice.actions;

export default startConversationSlice.reducer;

export const startConversation = (examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(startConversationStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/chatbot/send-message-to-conversation/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(startConversationSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(startConversationFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
