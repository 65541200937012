import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";

// axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  error: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { logoutStart, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export default logoutSlice.reducer;

export const logout = (token, refreshToken) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(logoutStart());
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/logout`,
      { refresh_token: refreshToken },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(logoutSuccess());
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("userData");

    return response; // Return the response from the API call
  } catch (error) {
    errorToast(error.response.data.error);
    dispatch(logoutFailure(error.response.data.message));
    throw error; // Rethrow the error to be caught by the try-catch block in submitHandler
  } finally {
    // dispatch(setLoading(false));
  }
};
