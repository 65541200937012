import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const attemptExamSlice = createSlice({
  name: "attemptExam",
  initialState,
  reducers: {
    attemptExamStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    attemptExamSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    attemptExamFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { attemptExamStart, attemptExamSuccess, attemptExamFailure } =
  attemptExamSlice.actions;

export default attemptExamSlice.reducer;

export const attemptExam = (examData, token) => async (dispatch) => {
  try {
    dispatch(attemptExamStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exam-attempt/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(attemptExamSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(attemptExamFailure(error.message));

    return { success: false, error: error.response.message };
  }
};
