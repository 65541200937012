import React from "react";
import { Typography, Box } from "@mui/material";

const PaymentSuccess = () => {
  return (
    <Box className="content-layout" marginTop="32px">
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box className="access-denied-found">
          <Box className="page-inner">
            <svg
              viewBox="0 0 24 24"
              class="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="#B0DC4C"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <Typography
              variant="h3"
              component="h3"
              style={{ textTransform: "initial" }}
            >
              <Box>Payment done!</Box>
              <Box style={{ marginTop: "8px" }}>
                Thanks for subscribing to OpenEssayMe.
              </Box>
              <Box style={{ marginTop: "8px" }}>
                You are now a part of a vibrant community of learners and
                explorers.
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
