import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import service from "../../assets/images/service2.png";
import tutor from "../../assets/images/tutor.png";
import practice from "../../assets/images/practice.png";
import feedback from "../../assets/images/feedback.png";
import bgImage from "../../assets/images/service-bg.png";

const Services = () => {
  return (
    <Box className="services-layout">
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12} className="pt-0">
          <img src={service} alt="service" className="service-img" />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} position="relative">
          <Box className="ser-content-layout">
            <Typography className="services-title">Specialties</Typography>
            <Typography className="home-tagline color-white" mt={1}>
              Our Services
            </Typography>
            <Box className="service-width">
              <Grid container spacing={2} mt={1}>
                <Grid item lg={1} md={1} sm={12} xs={12}>
                  <img src={practice} alt="practice" />
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                  <Typography className="service-points">
                    Online Essay Practice
                  </Typography>
                  <Typography className="service-description" mt={1}>
                    Our Online Essay Practice offers you a structured learning
                    environment where you can receive detailed instructions on
                    how to construct essays for major tests. Upon submission of
                    an essay, you will then receive tailored scoring and
                    feedback, indicating your potential performance on the
                    actual exam you will take in the future, whether its GED,
                    SAT, or some other major exam. This feedback also highlights
                    specific areas needing improvement, from argument strength,
                    prompt adherence to grammatical accuracy, ensuring that you
                    are well prepared to take your exam.
                  </Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={12} xs={12}>
                  <img src={practice} alt="practice" />
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                  <Typography className="service-points">
                    Online Grammar Practice
                  </Typography>
                  <Typography className="service-description" mt={1}>
                    Our Online Grammar Practice provides you with a rich and
                    focused learning experience where you explore essential
                    grammar rules through comprehensive examples. After
                    reviewing these principles, you can engage in unlimited
                    practice exercises tailored to reinforce your grammatical
                    understanding. This hands-on approach allows you to
                    continuously assess and refine your skills, ensuring that
                    you have a solid grasp of grammar that will enhance your
                    writing.
                  </Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={12} xs={12}>
                  <img src={tutor} alt="practice" />
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                  <Typography className="service-points">
                    24/7 AI Tutor
                  </Typography>
                  <Typography className="service-description" mt={1}>
                    Experience the interactive support of our OpenEssayMe AI
                    Tutor, designed to function like a personal classroom
                    instructor. Available at any time, the AI Tutor is ready to
                    assist you with any questions or challenges you might
                    encounter. Whether you need help deciphering complex essay
                    topics or navigating through grammatical nuances, our AI
                    Tutor offers immediate, personalized guidance to help you
                    overcome obstacles and ultimately pass the writing section
                    of your exams.
                  </Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={12} xs={12}>
                  <img src={feedback} alt="practice" />
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                  <Typography className="service-points">
                    Feedback Mechanism
                  </Typography>
                  <Typography className="service-description" mt={1}>
                    Our feedback mechanism is designed to cater specifically to
                    your learning needs, providing detailed and constructive
                    critiques of your essay and grammar practice. By applying
                    advanced educational strategies, our feedback focuses on
                    pinpointing exactly what you need to improve for the
                    specific exams you are preparing for. With each practice
                    session, you'll receive insights that are critical to
                    refining your skills and boosting your confidence, ensuring
                    that you are thoroughly prepared for your academic goals.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className="service-image">
              <img src={bgImage} alt="bgimage" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
