import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import aboutUs from "../../assets/images/AboutUs.png";
// import aboutUs2 from "../../assets/images/open.png";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <Box className="about-container">
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography className="home-title">About Us</Typography>
          <Typography className="home-tagline">
            A Coalition of Expert Educators Uniting to Pave the Way with
            Cutting-Edge Tools for Autonomous Learning
          </Typography>
          <Typography className="about-content">
            OpenEssayMe was created and designed by a dedicated team of
            experienced educators and professionals with a rich history of
            teaching students and preparing them for major exams like the GED,
            SAT, and more. Recognizing the urgent need for learners to have a
            dedicated space to practice and receive continuous feedback, we've
            channeled our expertise into creating a platform that empowers
            students to prepare autonomously and effectively, at their own pace.
            Motivated by our commitment to education and student success, we aim
            to make exam preparation accessible and impactful for all learners.
          </Typography>
          <Box mt={3}>
            <Typography className="about-points">Our Vision</Typography>
            <Typography className="about-description" mt={1}>
              Our vision is to democratize educational preparation by providing
              learners with the tools and understanding necessary to
              proficiently write essays for major exams. We envision a world
              where every student, regardless of their educational background or
              life circumstances, has equal access to the highest quality
              resources specifically designed to enhance their essay and grammar
              skills. This preparation not only aids them in passing their exams
              but also sets a solid foundation for their future educational and
              career endeavors.
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography className="about-points">Our Mission</Typography>
            <Typography className="about-description" mt={1}>
              Our mission is to equip students with state-of-the-art, tailored
              learning experiences that boost their essay writing and
              grammatical skills across major examinations like the GED, SAT,
              ACT, and GRE. By offering personalized practice, instant feedback,
              and access to our innovative AI tutor, we ensure that all learners
              can practice independently, improve continuously, and achieve
              their educational goals.
            </Typography>
          </Box>
          <Box mt={3}>
            {" "}
            <Box style={{ height: "35px" }}>
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={() => {
                  navigate("/login");
                }}
              >
                Get Started
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <img src={aboutUs} alt="about" className="about-img" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
