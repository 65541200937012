import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    contactStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    contactSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    contactFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { contactStart, contactSuccess, contactFailure } =
  contactSlice.actions;

export default contactSlice.reducer;

export const contactNotification = (examData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(contactStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/contact-us/`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(contactSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(contactFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    dispatch(setLoading(false));
  }
};
