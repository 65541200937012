import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  onEditGrammar: [],
  status: "idle",
  error: null,
};

const editGrammarSlice = createSlice({
  name: "editGrammar",
  initialState,
  reducers: {
    updateGrammarStart: (state) => {
      state.status = "loading";
    },
    updateGrammarSuccess: (state, action) => {
      state.status = "succeeded";
      state.onEditGrammar = action.payload.data;
    },
    updateGrammarFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  updateGrammarStart,
  updateGrammarSuccess,
  updateGrammarFailure,
} = editGrammarSlice.actions;

export const editGrammar = (id, examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(updateGrammarStart());
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/grammar/update/${id}`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(updateGrammarSuccess(response));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateGrammarFailure(error.response.data));
  } finally {
    // dispatch(setLoading(false));
  }
};

export default editGrammarSlice.reducer;
